import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import tagSystemPictureActions from '../store/tagSystem/pictures/actions';
import { entitySubtypes } from '../pages/tagSystem/config/config';



const useTagsOptions = (defaultGroups = []) => {
  const dispatch = useDispatch();
  const [ options, setOptions ] = useState([]);
  const [ currentGroups, setCurrentGroups ] = useState(defaultGroups);
  const {
    templates = [],
  } = useSelector(({
    tagSystemPictures }) => (
    {
      loading_structure: tagSystemPictures.loading_structure,
      templates: tagSystemPictures.pictureStructures,
    }
  ), shallowEqual);

  const baseGroups = [ entitySubtypes.main, entitySubtypes.usual, entitySubtypes.cloud ];

  const filterOptions = (data, groups = []) => {
    const tags = [];
    const subtypes = groups?.map((group) => group.replace('_tags', '')) || [];

    data?.forEach((template) => {
      template?.groups?.forEach((group) => {
        if (currentGroups?.length !== 4) {

          const isGroupAllowed = subtypes.length && (subtypes.includes(group.subtype) ||
            (subtypes.includes('other') && !baseGroups.includes(group.subtype)));

          if (!isGroupAllowed) {
            return;
          }
        }

        group?.tags?.forEach((tag) => {
          const isAdded = tags?.find((curTag) => tag.name === curTag.label);

          if (!isAdded) {
            tags.push({ value: tag.name, label: tag.name });
          }
        });
      });
    });

    return tags.sort((first, second) => first.label.localeCompare(second.label));
  };

  useEffect(() => {
    dispatch(tagSystemPictureActions.getPictureStructures());
  }, []);

  useEffect(() => {
    if (templates?.length) {
      setOptions(filterOptions(templates, currentGroups));
    }
  }, [ currentGroups, templates ]);

  return [ options, setCurrentGroups ];
};

export default useTagsOptions;
