import React from 'react';



const RuXflowNotification = () => {
  return (
    <div><p>
      Уважаемый Пользователь,
    </p><p>
      В связи с растущим числом законов об экономических и торговых санкциях («Санкции»), принимаемых во всех
      юрисдикциях, в которых мы работаем, мы стремимся соблюдать соответствующие санкции и готовы защитить наш бизнес от
      незаконного взаимодействия с любыми подсанкционными компаниями, организациями или лицами.
    </p><p>
      Для проведения необходимых процедур комплексной проверки <b>мы просим Вас предоставить нам копии следующих
        документов:</b>
    </p><p>
      (1) Копия действующего паспорта или документа, удостоверяющего личность государственного образца (с фотографией)
    </p><p>
      (2) Проверка личности с помощью селфи — ваша фотография с паспортом или документом, удостоверяющим личность.
    </p><p>
      (3) Подтверждение адреса не старше 12 месяцев, выданное на ваше имя и указывающее ваш адрес, например: счет за
      коммунальные услуги (вода, электричество, газ, телефон или Интернет) или выписка из банка, рекомендательное письмо
      из банка, официальное письмо/документы. от правительства. Мы надеемся на ваше понимание и сотрудничество при
      выполнении процедур KYC
    </p><p>
      (4) Подтверждение вашего номера IBAN / банковского счета (выписка из банка, рекомендательное письмо из банка, другой документ из вашего банка).
    </p><p>
      Мы надеемся на ваше понимание и сотрудничество при выполнении процедур KYC. Пожалуйста, не стесняйтесь задавать любые вопросы, если это необходимо.
    </p><p>
      Электронная почта для процедуры KYC: <b>kyc@bee-freelancer.app</b>
    </p>
    </div>
  );
};

export default RuXflowNotification;
