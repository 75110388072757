import React from 'react';
import imgURL from '../../../utils/imgURL';
import Chat from '../../../widgets/Chat';


/**
 * Builds a block with a list of tasks for the project information page
 *
 * @this ProjectInfo
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function () {
  const { lang, user } = this.props;
  const { comments, canComment } = this.computed;
  const {
    sizing,
  } = this.computed;

  return (
    <div
      className={`col-xl-${sizing.xl} col-lg-${sizing.lg} col-md-${sizing.md} col-sm-${sizing.sm} col-12`}
    >
      <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title font-weight-bold">
              {lang['PROJECT.COMMENTS']}
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="tab-content">
            <div className="tab-panel active">
              <div className="kt-widget4">
                <Chat
                  canComment={canComment}
                  ref={this._refComments}
                  messages={comments.map((comment) => ({
                    id: comment.id,
                    user_id: comment.user_id,
                    avatar: (comment.user && comment.user.avatar) || '',
                    name: (comment.user ? `${comment.user.username}` : `User #${comment.user_id}`),
                    time: comment.created_at,
                    updated_at: comment.updated_at,
                    deleted_at: comment.deleted_at,
                    isYou: comment.user_id === user.id,
                    status: comment.status,
                    is_hidden: comment.is_hidden,
                    message: (
                      <>
                        {comment.comment}
                        {comment.files && comment.files.length ? comment.files.map((file) => (
                          (
                            <p key={file.id} className="w-100 d-block kt-m-0">
                              <a
                                className="font-weight-bold"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={imgURL(file.link)}
                              >
                                {lang['PROJECT.ATTACHED_FILE']}
                              </a>
                            </p>
                          )))
                          : ''}
                      </>
                    ),
                  }))}
                  onSend={this._addComment}
                  onUpdateComment={this._updateComment}
                  onDeleteComment={this._deleteComment}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
