import React from 'react';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { addTagsToProject, removeTagsFromProject } from '../../../requests/tags';
import SelectHashtag from '../../../widgets/SelectHashtag';
import checkRole from '../../../utils/checkRole';
import taskTypes from '../taskTypes';
import groupsRoles from '../../customers/roles/groupsRoles';
import Modal from '../../../widgets/Modal';
import { status } from '../../../utils/statusToColor';
import canSetTags from '../helpers/canSetTags';
import uniqTags from '../helpers/uniqTags';



const SetTagsModal = ({ isActive, showModal, task, userId, setTags }) => {
  const lang = useSelector((state) => state.language.lang);

  const canRemoveAllTags = () => {
    return (task.project && task.project.status !== status.finished) && (
      (task.executor_id === userId
        || (task.project && task.project.manager_id === userId)
      ) && checkRole([ taskTypes[task.type].role ], [ ...groupsRoles.editors.all, ...groupsRoles.managers.all ])
    );
  };

  const canRemoveTags = () => {
    return (
      canRemoveAllTags() || (task.executor_id === userId && task.status === status.process)
    );
  };

  return (
    <Modal
      title={lang['MENU.TAGS']}
      show={isActive}
      bodyStyle={{ overflow: 'unset' }}
      close={() => {
        showModal(false, task.id);
      }}
      footer={
        <button
          className="btn btn-secondary"
          onClick={() => {
            showModal(false, task.id);
          }}
        >OK</button>
      }
    >
      <div className='d-flex justify-content-center flex-wrap'>
        {uniqTags(task).map((tag) => {
          return (
            <Button
              key={tag.id}
              variant="label-dark-o2"
              size="sm"
              className="kt-ml-5 kt-mb-5 d-flex align-items-center"
            >
              {tag.name}
              {(canRemoveAllTags() || (canRemoveTags())) && (
                <i
                  role="link"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className="fa fa-times-circle hover kt-mr-0 kt-ml-5"
                  onClick={async () => {
                    await removeTagsFromProject([ tag.id ], task.project_id);
                    setTags(task.project.tags.filter((item) => item.id !== tag.id));
                  }}
                />
              )}
            </Button>
          );
        })}
      </div>
      {canSetTags(task, userId) && (
        <>
          <hr />
          <div className='d-flex flex-column'>
            <Form.Group className="kt-mb-0">
              <Form.Label>{lang['TAGS.ADD']}</Form.Label>
              <SelectHashtag
                value={[]}
                canCreate={checkRole(
                  [ taskTypes[task.type].role ],
                  [ ...groupsRoles.managers.all ]
                )}
                filter={(tag) => (task.project.tags.findIndex((item) => item.id === tag.id) === -1)}
                onChange={async (vals, tags) => {
                  await addTagsToProject(vals, task.project_id);

                  setTags([ ...task.project.tags, {
                    user_id: userId,
                    id: tags[0].value,
                    name: tags[0].label,
                    slug: tags[0].slug,
                  } ]);
                }}
              />
            </Form.Group>
          </div>
        </>
      )}
    </Modal>
  );
};

SetTagsModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  setTags: PropTypes.func.isRequired,
};

SetTagsModal.defaultProps = {
  userId: 0,
};

export default SetTagsModal;
