import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import PropsTypes from 'prop-types';
import { getTitle, getValue } from '../utils/filters';



const colors = [ 'brand', 'success', 'info', 'danger', 'dark' ];

const propTypes = {
  filter: PropsTypes.object,
  onRemoveFilter: PropsTypes.func,
  options: PropsTypes.shape({
    executor: PropsTypes.shape({
      username: PropsTypes.string,
    }),
    prev_executor: PropsTypes.shape({
      username: PropsTypes.string,
    }),
    country: PropsTypes.string,
  }),
  filters: PropsTypes.any,
  isFromStatistics: PropsTypes.oneOfType([ PropsTypes.bool, PropsTypes.number ]),
  lang: PropsTypes.any,
  categories: PropsTypes.any,
};

/**
 * Available Filters
 * If not in the list then it will not be displayed
 *
 * @type {string[]}
 */

const listFilters = [
  'project',
  'created',
  'title',
  'deadline',
  'taps',
  'hashtags',
  'tags',
  'type',
  'image_type',
  'priority',
  'executor',
  'prev_executor',
  'role',
  'country',
  'inactive',
  'name',
  'finished_at',
  'category_id',
  'project_hc_release_date',
  'project_hc_content_type',
];

/**
 * Component for displaying a list of selected filters
 *
 * @returns {*}
 */
class ViewFilters extends React.Component {
  state = {
    show: false,
  };

  constructor (props) {
    super(props);

    this.state.show = sessionStorage.getItem('showFilter') ? (Date.now() - Number(sessionStorage.getItem('showFilter')) < 5000) : false;
    if (this.state.show) {
      this.filterTimeout = setTimeout(() => {
        this.filterTimeout = null;
        this.setState({
          show: false,
        });
      }, 1000);
    }
  }

  refOverlay = React.createRef();

  render () {
    const { filters, isFromStatistics } = this.props;

    const keysFilters = Object.keys(filters).filter((k) => listFilters.findIndex((value) => value === k) !== -1);

    return keysFilters.length > 0 && (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <div
        className={`filters${this.state.show ? ' filters--full' : ''}`}
        onMouseEnter={() => {
          if (this.filterTimeout) {
            clearTimeout(this.filterTimeout);
            this.filterTimeout = null;
          }
          sessionStorage.setItem('showFilter', `${Date.now()}`);
          this.setState({
            show: true,
          });
        }}
        onMouseOver={() => {
          if (this.filterTimeout) {
            clearTimeout(this.filterTimeout);
            this.filterTimeout = null;
          }
        }}
        onMouseLeave={() => {
          sessionStorage.removeItem('showFilter');
          this.setState({
            show: false,
          });
        }}
      >
        <div className="filters__wrapper" title={this.filterContent}>
          {keysFilters.map((key, index) => {
            return (
              <div className="filters__btn" key={key}>
                <Button
                  as='span'
                  variant={colors[index % colors.length]}
                  className="btn-elevate btn-pill"
                >
                  {this.buildViewFilter(key, filters[key])}
                  {isFromStatistics && (key === 'taps' || key === 'category_id') ? (
                    <i />
                  ) : (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <i
                      role="link"
                      tabIndex={0}
                      className='fa fa-window-close kt-ml-a'
                      onClick={() => {
                        this.props.onRemoveFilter(key);
                      }}
                    />
                  )}
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.clickOver);
  }

  /**
   * Click Over
   *
   * @param {EventListenerOrEventListenerObject} event
   * @returns {void}
   */
  clickOver = (event) => {
    const path = event.path || (event.composedPath && event.composedPath()) || [];

    if (path.indexOf(this.refOverlay.current) === -1) {
      document.removeEventListener('click', this.clickOver);
      event.stopPropagation();
      this.setState({
        show: false,
      });
    }
  };

  /**
   * build view filter
   *
   * @param {string} key
   * @param {any} filter
   * @returns {JSX.Element}
   */
  buildViewFilter = (key, filter) => {
    const title = this.getTitle(key);
    const value = this.getValue(key, filter, this.props.categories);
    const isNotEmptyArray = key === 'tags' && value.length > 1;

    this.filterContent = isNotEmptyArray ? value.join(', ') : value;

    return (
      <>
        <span className='font-weight-bold text-left'>{`${title}:`}</span>
        <br />
        <span className='kt-ml-5 text-left text-truncate'>
          {isNotEmptyArray ? value.join(', ') : value}
        </span>
      </>
    );
  };

  /**
   * Get Title
   *
   * @param {string} key
   * @returns {*}
   */
  getTitle = (key) => {
    const { lang } = this.props;

    return getTitle(key, lang);
  };

  /**
   * Get value
   *
   * @param {any} key
   * @param {any} filter
   * @param {Array} categories
   * @returns {*}
   */
  getValue = (key, filter, categories) => {
    const { lang, options } = this.props;

    return getValue(key, filter, lang, options, categories);
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    categories: store.categories,
  };
};

ViewFilters.propTypes = propTypes;

export default connect(mapStoreToProps)(ViewFilters);
