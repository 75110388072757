import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import useTagsOptions from '../../hooks/useTagsOptions';



const SelectTag = ({
  name,
  value,
  selectedGroups,
  onChange,
}) => {
  const types = [ 'main_tags', 'usual_tags', 'cloud_tags', 'other_tags' ];
  const [ selectedTagsGroup, setSelectedTagsGroup ] = useState(types);
  const [ options, filterOptions ] = useTagsOptions(selectedTagsGroup);

  useEffect(() => {
    if (selectedGroups?.length > 0) {
      setSelectedTagsGroup(selectedGroups);
      filterOptions(selectedGroups);
    }
  }, [ selectedGroups ]);

  const handleChange = (value) => {
    const data = value?.map((item) => {
      if (item?.hasOwnProperty('value')) {
        return item.value;
      }
      return item;
    });

    onChange(data, selectedTagsGroup);
  };

  const handleGroupChange = (event) => {
    const { name, checked } = event.target;

    setSelectedTagsGroup((prevSelected) => {
      let groups = [];

      if (checked) {
        groups = [ ...prevSelected, name ];
        filterOptions(groups);

        return groups;
      }

      groups = prevSelected.filter((type) => type !== name);
      filterOptions(groups);

      if (!groups.length) {
        onChange([], []);
      }

      return groups;
    });
  };

  useEffect(() => {
    if (options.length > 0) {
      handleChange(options.filter((item) => value?.includes(item.label)));
    }
  }, [ options ]);

  return (
    <div>
      <div className="d-flex justify-between">
        {types.map((type) => (
          <Form.Check
            key={type}
            type="checkbox"
            className="mr-4"
          >
            <Form.Check.Label>
              <Form.Check.Input
                name={type}
                type="checkbox"
                checked={selectedTagsGroup.includes(type)}
                onChange={handleGroupChange}
              />
              {type}
            </Form.Check.Label>
          </Form.Check>
        ))}
      </div>
      <Select
        isMulti
        name={name}
        value={options.filter((item) => value?.includes(item.label))}
        options={options}
        onChange={handleChange}
        components={{
          MenuList: (props) => {
            return (
              <components.MenuList {...props}>
                <div className='d-flex flex-wrap justify-content-between'>
                  {props.children}
                </div>
              </components.MenuList>
            );
          },
          Option: (props) => {
            return (
              <div
                style={{
                  backgroundColor: 'hsl(0,0%,90%)',
                  borderRadius: 2,
                  display: 'flex',
                  margin: 2,
                  minWidth: 0,
                  boxSizing: 'border-box',
                  flex: '1 0 auto',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <components.Option {...props}>
                  {props.children}
                </components.Option>
              </div>);
          },
        }}
      />
    </div>
  );
};

SelectTag.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  selectedGroups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectTag;
