import { orderBy } from 'lodash';
import { formatDateTimeToString, formatStringToDate } from '../utils/formats';
import { filterTasksAction, setTasksAction, updateTaskAction } from '../store/tasks';
import { prevMonthFirstDate, prevMonthLastDate } from '../../const/date';
import axiosApiInstance from './utils/api';
// eslint-disable-next-line import/no-named-as-default
import notification from './notifications';



export const nameTasks = 'tasks';


export const getTasksForExecutor = async (idExecutor) => {
  const URL = `tasks/list/all?executor=${idExecutor}`;

  try {
    const response = await axiosApiInstance.get(URL);

    if (!response?.data) {
      return [];
    }

    return orderBy(response?.data?.tasks, (task) => task.id);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error?.response?.data);

    return { tasks: [] };
  }
};

// eslint-disable-next-line consistent-return
export const getTasksForBonuses = async (userId, tasksReadyToPay) => {
  const URL = `tasks?executor=${userId}&from=${prevMonthFirstDate}&to=${prevMonthLastDate}&type=payment`;

  try {
    const response = await axiosApiInstance.get(URL, {});

    if (response) {
      const data = response.data;

      data.tasks = orderBy(data.tasks, (task) => task.id);

      return data.tasks.filter((task) => (tasksReadyToPay.includes(task.id)));
    }
  } catch (error) {
    if (error.response) {
      // eslint-disable-next-line no-console
      console.error(error.response.data);
      return { tasks: [] };
    }
  }
};

export const getBatchTasksForBonuses = async (users) => {
  const URL = `tasks/batch/bonuses?from=${prevMonthFirstDate}&to=${prevMonthLastDate}&type=payment`;

  try {
    const response = await axiosApiInstance.post(URL, {
      users,
    });

    return response.data.map((tasks) => {
      const tempTasks = {};

      tempTasks.tasks = orderBy(tasks.tasks, (task) => task.id);

      let user = null;

      return tempTasks.tasks.filter((task) => {
        if (!user) {
          user = users.find((user) => user.user.id === task.executor_id);
        }

        if (user) {
          return user.tasks.includes(task.id);
        }

        return [];
      });
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return [];
  }

};

export const getTaskById = async (id) => {
  const URL = `tasks/${id}`;

  try {
    const task = await axiosApiInstance.get(URL);

    task.data.task.files.forEach((file) => {
      file.prefix = file.prefix.replace('_', '.');
    });

    task.data.task.project_files = [ ...task.data.task.project.files ];

    return task.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return {};
};

/**
 * Gets list tasks by a list ids
 *
 * @param {Array} ids
 * @returns {Promise<*[]>}
 */
export const getTasksByIds = async (ids) => {
  try {
    return (await Promise.allSettled(ids.map((id) => getTaskById(id)))).map((res) => res.value.task);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return [];
  }
};

// eslint-disable-next-line consistent-return
export const getTasks = (url, type, filteredParams = {}) => async (dispatch) => {
  try {
    const response =  await axiosApiInstance.get(url, {});

    if (response.data) {
      const tasks = response.data.tasks;

      tasks.forEach((task) => {
        task.deadline_to = formatStringToDate(task.deadline_to);
      });

      dispatch(setTasksAction(tasks, type));
      dispatch(filterTasksAction(filteredParams, type));

      return tasks;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

// eslint-disable-next-line consistent-return
export const getTasksTypeForExecutor = async (url) => {
  try {
    const response = await axiosApiInstance.get(url);

    if (response.data) {
      const msg = response.data;

      Object.values(msg.tasks).forEach((task) => {
        task.deadline_to = formatStringToDate(task.deadline_to);
      });
      return response.data;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const setTasks = (tasks, type) => (dispatch) => {
  try {
    dispatch(setTasksAction(tasks, type));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const updateTask = (updatedTask, categoryId, tasksType) => (dispatch) => {
  try {
    dispatch(updateTaskAction(updatedTask, categoryId, tasksType));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const filterTasks = (params, tasksType) => (dispatch) => {
  try {
    dispatch(filterTasksAction(params, tasksType));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const loadRefFiles = (data, onSuccess, onError, setLoader) => {
  setLoader(true);
  const fd = new FormData();

  fd.append('_method', 'POST');
  fd.append('task_id', data.task.id);
  fd.append('project_id', data.task.project.id);
  fd.append('upload_stage', data.task.type);
  fd.append('prompt', data.prompt);

  data.files.forEach((refFile, index) => {
    fd.append(`references[${index}]`, refFile.file, refFile.file.name);
  });

  axiosApiInstance.post('references', fd, {})
    .then(() => {
      // if (data.selectedExecutor) {
      //   chooseExecutor(data.selectedExecutor);
      // }

      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      notification('NOTIFICATION.SUCCESS_TASK_LOAD_FILES', 'success');
    })
    .catch((error) => {
      if (typeof onError === 'function') {
        onError(error);
      }
      notification('NOTIFICATION.ERROR_TASK_LOAD_FILES', 'error');
      if (error.response.status === 422) {
        notification('NOTIFICATION.WRONG_FILE_TYPE', 'error');
      }
    })
    .finally(() => {
      setLoader(false);
    });
};

export const chooseExecutor = (data, onSuccess, onError) => {
  if (data.files?.length) {
    loadRefFiles(data, null, null, data.setLoader);
  }

  axiosApiInstance.post(`tasks/${data.task.id}/executor`, {
    '_method': 'PUT',
    'executor_id': data.selectedExecutor,
    'prompt': data.prompt,
    'deadline': formatDateTimeToString(data.selectedDeadline),
  })
    .then(() => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      notification('NOTIFICATION.SUCCESS_TASK_CHOOSE_EXECUTOR', 'success');
    })
    .catch((error) => {
      if (typeof onError === 'function') {
        onError(error);
      }

      if (error.response) {
        notification('NOTIFICATION.ERROR_TASK_CHOOSE_EXECUTOR', 'error');
      }
    });
};
