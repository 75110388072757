import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import React from 'react';
import PropTypes from 'prop-types';
import { filter, isEmpty } from 'lodash';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import Modal from '../../../widgets/Modal';
import imageType from '../../projects/image_type';
import { addTagsToProject, removeTagsFromProject } from '../../../requests/tags';
import DropFile from '../../../widgets/DropFile';
import SelectHashtag from '../../../widgets/SelectHashtag';
import checkRole from '../../../utils/checkRole';
import taskTypes from '../taskTypes';
import groupsRoles from '../../customers/roles/groupsRoles';
import { REGEXP_NUM_VALUE } from '../../../../const/system';
import roles from '../../customers/roles/roles';
import {
  colorComplexityLevels,
  contourComplexityLevels,
  getPriceByColorComplexityLevel,
  getPriceByContourComplexityLevel,
} from '../../projects/projectPrices';
import { floatValidator } from '../../../utils/validators';
import {
  COLOR_PRICE,
  CONTOUR_PRICE,
  HIGHEST_COMPLEXITY_LEVEL,
  MIN_MESSAGE_LENGTH_OF_RETURN_WORk, SPECIAL_TAGS,
} from '../../../../const/project';
import HtmlEditor from '../../../components/HtmlEditor';
import { addCategoriesToProject, removeCategoriesFromProject } from '../../../requests/categories';
import SelectCategories from '../../../widgets/SelectCategories';
import { notification } from '../../../requests/notifications';
import config from '../../../../config/app';
import MyDatePicker from '../../../widgets/DatePicker';
import { formatStringToDate } from '../../../utils/formats';
import TagSystemPictureTagsModal from '../modal/TagSystemPictureTagsModal';



const propTypes = {
  lang: PropTypes.object.isRequired,
  task: PropTypes.object,
  reasons: PropTypes.any,
  showModalTags: PropTypes.bool,
  showModalCategories: PropTypes.bool,
  showModalTagSystemPictureTags: PropTypes.bool,
  showModalSetBonus: PropTypes.bool,
  showModalDeleteBonus: PropTypes.bool,
  showModalEditType: PropTypes.bool,
  showModalReopenTask: PropTypes.bool,
  showModalApplyTask: PropTypes.bool,
  showModalLimitReached: PropTypes.bool,
  showModalEditContourComplexityLevel: PropTypes.bool,
  showModalEditColorComplexityLevel: PropTypes.bool,
  isAlreadyHaveBonus: PropTypes.bool,
  userId: PropTypes.number,
  canBonus: PropTypes.bool.isRequired,
  canSetTags: PropTypes.bool,
  uniqTags: PropTypes.any,
  canRemoveTags: PropTypes.bool,
  setModalApplyTask: PropTypes.func,
  setModalSetBonusValues: PropTypes.func,
  setModalSetBonus: PropTypes.func,
  setModalDeleteBonus: PropTypes.func,
  setModalEditType: PropTypes.func,
  setModalEditField: PropTypes.func,
  updateComplexityLevel: PropTypes.func,
  setModalLimitReached: PropTypes.func,
  reopenTask: PropTypes.any,
  finishTask: PropTypes.any,
  editImageCategoryProject: PropTypes.func,
  setModalTags: PropTypes.func,
  setModalReopenTask: PropTypes.func,
  canRemoveAllTags: PropTypes.bool,
  createBonus: PropTypes.func,
  updateBonus: PropTypes.func,
  deleteBonus: PropTypes.func,
  setTags: PropTypes.any,
  reason: PropTypes.string,
  bonusAmount: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  userRoles: PropTypes.array,
  currentLang: PropTypes.string,
  canSetCategories: PropTypes.bool,
  categories: PropTypes.any,
  projectCategories: PropTypes.any,
  setModalCategories: PropTypes.func,
  setCategories: PropTypes.any,
  getTaskDeadline: PropTypes.func,
  setModalTagSystemPictureTags: PropTypes.func,
  tagSystemPicture: PropTypes.object,
};

/**
 * Component of modal task page windows
 */

class TaskInfoModals extends React.PureComponent {
  state = {
    returnFiles: [],
    message: '',
    curReasons: [],
    newType: '',
    testingTime: 0,
    newArtistPrice: CONTOUR_PRICE,
    newDesignerPrice: COLOR_PRICE,
    newContourComplexityLevel: '2',
    newColorComplexityLevel: '1',
    selectedDeadline: '',
  };

  componentDidMount () {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      newContourComplexityLevel: this.props.task.project.options?.contour_complexity_level || '2',
      newArtistPrice: typeof this.props.task.project.options?.artist_price === 'undefined' ? CONTOUR_PRICE : this.props.task.project.options?.artist_price,
      newColorComplexityLevel: this.props.task.project.options?.color_complexity_level || '1',
      newDesignerPrice: typeof this.props.task.project.options?.designer_price === 'undefined' ? COLOR_PRICE : this.props.task.project.options?.designer_price,
    });
  }

  isReopenTaskDisabled = () => {
    if (checkRole(this.props.userRoles, [ roles['coloring-tester'].key ])) {
      return (!this.state.curReasons.length && this.state.message.trim().length < MIN_MESSAGE_LENGTH_OF_RETURN_WORk) || Number(this.state.testingTime) < 1;
    }

    return !this.state.curReasons.length && this.state.message.trim().length < MIN_MESSAGE_LENGTH_OF_RETURN_WORk;
  };

  onReturnTask = (event) => {
    event?.preventDefault();
    const currentLang = this.props.currentLang;

    if (this.state.curReasons.length > 0 || (this.state.message.trim().length >= MIN_MESSAGE_LENGTH_OF_RETURN_WORk)) {
      this.props.reopenTask({
        message: (
          (
            this.state.curReasons.length > 0
              ? `${this.props.reasons
                .filter((reason, index) => this.state.curReasons.includes(index)).map((reason) => reason[currentLang])
                .join('. ')}. `
              : ''
          ) + this.state.message
        ),
        returnFiles: this.state.returnFiles,
        time: this.state.testingTime,
        selectedDeadline: this.state.selectedDeadline,
      });
    }
  };

  render () {
    const { returnFiles, message, testingTime } = this.state;

    const {
      task,
      reasons,
      showModalTags,
      showModalCategories,
      showModalTagSystemPictureTags,
      showModalSetBonus,
      showModalDeleteBonus,
      showModalEditType,
      showModalReopenTask,
      showModalApplyTask,
      showModalLimitReached,
      showModalEditContourComplexityLevel,
      showModalEditColorComplexityLevel,
      lang,
      userId,
      canBonus,
      canSetTags,
      uniqTags,
      reason,
      bonusAmount,
      canRemoveAllTags,
      canRemoveTags,
      isAlreadyHaveBonus,
      setModalSetBonus,
      setModalDeleteBonus,
      setModalSetBonusValues,
      setModalApplyTask,
      setModalReopenTask,
      setModalLimitReached,
      setModalEditType,
      setModalEditField,
      updateComplexityLevel,
      setModalTags,
      finishTask,
      createBonus,
      updateBonus,
      deleteBonus,
      setTags,
      editImageCategoryProject,
      currentLang,
      canSetCategories,
      projectCategories,
      setModalCategories,
      setModalTagSystemPictureTags,
      tagSystemPicture,
      setCategories,
      getTaskDeadline,
    } = this.props;

    return (
      <>
        <Modal
          title={lang['PROJECT.CHANGE_CONTOUR_COMPLEXITY_LEVEL']}
          show={showModalEditContourComplexityLevel}
          close={() => {
            setModalEditField(false, 'showModalEditContourComplexityLevel');
            this.setState({
              newContourComplexityLevel: task.project.options.contour_complexity_level,
              newArtistPrice: typeof task.project.options?.artist_price === 'undefined' ? getPriceByContourComplexityLevel(task.project.options?.contour_complexity_level) : task.project.options?.artist_price,
            });
          }}
          footer={(
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setModalEditField(false, 'showModalEditContourComplexityLevel');
                  this.setState({
                    newContourComplexityLevel: task.project.options.contour_complexity_level,
                    newArtistPrice: typeof task.project.options?.artist_price === 'undefined' ? getPriceByContourComplexityLevel(task.project.options?.contour_complexity_level) : task.project.options?.artist_price,
                  });
                }}
              >
                {lang['GLOBAL.CANCEL']}
              </button>
              <button
                className="btn btn-success"
                disabled={(Number(this.state.newContourComplexityLevel) === Number(task.project.options?.contour_complexity_level) && Number(this.state.newContourComplexityLevel) !== HIGHEST_COMPLEXITY_LEVEL) || (Number(this.state.newContourComplexityLevel) === HIGHEST_COMPLEXITY_LEVEL && Number(this.state.newContourComplexityLevel) === Number(task.project.options?.contour_complexity_level) && Number(this.state.newArtistPrice) === Number(task.project.options?.artist_price))}
                onClick={() => {
                  updateComplexityLevel(this.state.newContourComplexityLevel, 'contour_complexity_level', parseFloat(this.state.newArtistPrice));
                  setModalEditField(false, 'showModalEditContourComplexityLevel');
                }}
              >
                {lang['GLOBAL.EDIT']}
              </button>
            </>
          )}
        >
          <Form.Group>
            <Form.Label>{lang['PROJECT.FIELDS.contour_complexity_level']}</Form.Label>
            <Form.Control
              as="select"
              value={this.state.newContourComplexityLevel}
              onChange={(event) => {
                this.setState({
                  newContourComplexityLevel: event.target.value,
                });
              }}
            >
              {contourComplexityLevels.map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </Form.Control>
            {Number(this.state.newContourComplexityLevel) === HIGHEST_COMPLEXITY_LEVEL && (
              <>
                <Form.Label>{lang['PROJECT.FIELDS.artist_price']}</Form.Label>
                <Form.Control
                  pattern="[A-Za-z]{3}"
                  value={this.state.newArtistPrice}
                  onChange={(event) => {
                    this.setState({
                      newArtistPrice: floatValidator(event.target.value),
                    });
                  }}
                />
              </>
            )}
          </Form.Group>
        </Modal>

        <Modal
          title={lang['PROJECT.CHANGE_COLOR_COMPLEXITY_LEVEL']}
          show={showModalEditColorComplexityLevel}
          close={() => {
            setModalEditField(false, 'showModalEditColorComplexityLevel');
            this.setState({
              newColorComplexityLevel: task.project.options.color_complexity_level,
              newDesignerPrice: typeof task.project.options.designer_price === 'undefined' ? getPriceByColorComplexityLevel(task.project.options.color_complexity_level) : task.project.options.designer_price,
            });
          }}
          footer={(
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setModalEditField(false, 'showModalEditColorComplexityLevel');
                  this.setState({
                    newColorComplexityLevel: task.project.options.color_complexity_level,
                    newDesignerPrice: typeof task.project.options.designer_price === 'undefined' ? getPriceByColorComplexityLevel(task.project.options.color_complexity_level) : task.project.options.designer_price,
                  });
                }}
              >
                {lang['GLOBAL.CANCEL']}
              </button>
              <button
                className="btn btn-success"
                disabled={(Number(this.state.newColorComplexityLevel) === Number(task.project.options.color_complexity_level) && Number(this.state.newColorComplexityLevel) !== HIGHEST_COMPLEXITY_LEVEL) || (Number(this.state.newColorComplexityLevel) === HIGHEST_COMPLEXITY_LEVEL && Number(this.state.newColorComplexityLevel) === Number(task.project.options.color_complexity_level) && Number(this.state.newDesignerPrice) === Number(task.project.options?.designer_price))}
                onClick={() => {
                  updateComplexityLevel(this.state.newColorComplexityLevel, 'color_complexity_level', parseFloat(this.state.newDesignerPrice));
                  setModalEditField(false, 'showModalEditColorComplexityLevel');
                }}
              >
                {lang['GLOBAL.EDIT']}
              </button>
            </>
          )}
        >
          <Form.Group>
            <Form.Label>{lang['PROJECT.FIELDS.color_complexity_level']}</Form.Label>
            <Form.Control
              as="select"
              value={this.state.newColorComplexityLevel}
              onChange={(event) => {
                this.setState({
                  newColorComplexityLevel: event.target.value,
                });
              }}
            >
              {colorComplexityLevels.map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </Form.Control>
            {Number(this.state.newColorComplexityLevel) === HIGHEST_COMPLEXITY_LEVEL && (
              <>
                <Form.Label>{lang['PROJECT.FIELDS.designer_price']}</Form.Label>
                <Form.Control
                  value={this.state.newDesignerPrice}
                  onChange={(event) => {
                    this.setState({
                      newDesignerPrice: floatValidator(event.target.value),
                    });
                  }}
                />
              </>
            )}
          </Form.Group>
        </Modal>

        <Modal
          title={lang['TASK.CONTACT_YOUR_EDITOR']}
          showFooter={false}
          show={showModalLimitReached}
          close={() => {
            setModalLimitReached(false);
          }}
        />

        <Modal
          title={lang['PROJECT.CHANGE_IMAGE_TYPE']}
          show={showModalEditType}
          close={() => {
            setModalEditType(false);
          }}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setModalEditType(false);
                }}
              >
                {lang['GLOBAL.CANCEL']}
              </button>
              <button
                className="btn btn-success"
                disabled={!this.state.newType || this.state.newType === task.project.options.type}
                onClick={() => {
                  if (this.state.newType && this.state.newType !== task.project.options.type) {
                    editImageCategoryProject(this.state.newType, task.project.id);
                  }
                }}
              >
                {lang['GLOBAL.EDIT']}
              </button>
            </>
          }
        >
          <Form.Group>
            <Form.Label>{lang['PROJECT.FIELDS.type']}</Form.Label>
            <Form.Control
              as={'select'}
              value={this.state.newType || (task.project && task.project.options && task.project.options.type)}
              onChange={(event) => {
                this.setState({
                  newType: event.target.value,
                });
              }}
            >
              {imageType.map((type) => {
                return (
                  <option key={type} value={type}>
                    {type}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Modal>

        <Modal
          title={lang['MENU.TAGS']}
          show={showModalTags}
          bodyStyle={{ overflow: 'unset' }}
          close={() => {
            setModalTags(false);
          }}
          footer={
            <button
              className="btn btn-secondary"
              onClick={() => {
                setModalTags(false);
              }}
            >OK</button>
          }
        >
          <div className='d-flex justify-content-center flex-wrap'>
            {uniqTags.map((tag) => {
              return (
                <Button
                  key={tag.id}
                  variant="label-dark-o2"
                  size="sm"
                  className="kt-ml-5 kt-mb-5 d-flex align-items-center"
                >
                  {tag.name}
                  {(canRemoveAllTags || (canRemoveTags)) && (
                    <i
                      role="link"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      className="fa fa-times-circle hover kt-mr-0 kt-ml-5"
                      onClick={async () => {
                        await removeTagsFromProject([ tag.id ], task.project_id);
                        setTags(task.project.tags.filter((item) => item.id !== tag.id));
                      }}
                    />
                  )}
                </Button>
              );
            })}
          </div>
          {canSetTags && (
            <>
              <hr />
              <div className='d-flex flex-column'>
                <Form.Group className="kt-mb-0">
                  <Form.Label>{lang['TAGS.ADD']}</Form.Label>
                  <SelectHashtag
                    value={[]}
                    canCreate={checkRole(
                      [ taskTypes[task.type].role ],
                      [ ...groupsRoles.managers.all ]
                    )}
                    filter={(tag) => {
                      const inProject = (task.project.tags.findIndex((item) => item.id === tag.value) === -1);

                      if (checkRole(this.props.userRoles, [ roles['coloring-artist'].key ])) {
                        return inProject && !SPECIAL_TAGS.includes(tag.slug);
                      }

                      return inProject;
                    }}
                    onChange={async (vals, tags) => {
                      await addTagsToProject(vals, task.project_id);

                      setTags([ ...task.project.tags, {
                        user_id: userId,
                        id: tags[0].value,
                        name: tags[0].label,
                        slug: tags[0].slug,
                      } ]);
                    }}
                  />
                </Form.Group>
              </div>
            </>
          )}
        </Modal>

        <Modal
          title={lang['MENU.CATEGORIES']}
          show={showModalCategories}
          close={() => {
            setModalCategories(false);
          }}
          bodyStyle={{ overflow: 'unset' }}
          footer={(
            <Button
              variant="secondary"
              onClick={() => {
                setModalCategories(false);
              }}
            >
              OK
            </Button>
          )}
        >
          <div className="d-flex justify-content-center flex-wrap">
            {projectCategories.map((category) => (
              <Button
                key={category.id}
                variant="label-dark-o2"
                size="sm"
                className="kt-ml-5 kt-mb-5 d-flex align-items-center"
              >
                {category.name}
                {canSetCategories && (
                  <i
                    role="link"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    className="fa fa-times-circle hover kt-mr-0 kt-ml-5"
                    onClick={async () => {
                      const response = await removeCategoriesFromProject([ category.id ], task.project.id, this.props.notification);

                      if (response) {
                        setCategories(task.project.categories.filter((item) => item.id !== category.id));
                      }
                    }}
                  />
                )}
              </Button>
            ))}
          </div>
          {canSetCategories && (
            <>
              <hr />
              <div className="d-flex flex-column">
                <Form.Group className="kt-mb-0">
                  <Form.Label>{lang['TAGS.ADD_CATEGORY']}</Form.Label>
                  <SelectCategories
                    filter={(category) => (this.props.categories.findIndex(
                      () => (category.value === task.project.options?.category_id)
                    ) === -1) && projectCategories.findIndex((item) => item.id === category.value) === -1}
                    value={[]}
                    onChange={async (values, projectCategories) => {
                      let response;

                      if (projectCategories.length) {
                        response = await addCategoriesToProject(values, task.project.id, this.props.notification);
                      }
                      if (response) {
                        setCategories([ ...task.project.categories, {
                          user_id: userId,
                          id: projectCategories[0].value,
                          name: projectCategories[0].label,
                          slug: projectCategories[0].slug,
                        } ]);
                      }
                    }}
                  />
                </Form.Group>
              </div>
            </>
          )}
        </Modal>

        <Modal
          title={isAlreadyHaveBonus ? lang['BONUSES.CHANGE_AWARD'] : lang['BONUSES.ADD_AWARD']}
          show={showModalSetBonus}
          bodyStyle={{ overflow: 'unset' }}
          close={() => {
            setModalSetBonus(false);
          }}
          footer={
            <Button
              type='submit'
              variant='success'
              disabled={
                isAlreadyHaveBonus ? ((bonusAmount && !bonusAmount.match(REGEXP_NUM_VALUE)) || isEmpty(reason)) :
                  (isEmpty(bonusAmount) || !bonusAmount.match(REGEXP_NUM_VALUE) || isEmpty(reason))
              }
              onClick={async () => {
                if (isAlreadyHaveBonus) {
                  updateBonus(task.bonus.id, bonusAmount, reason);
                } else {
                  createBonus(bonusAmount, reason);
                }
              }}
            >
              {isAlreadyHaveBonus ? lang['GLOBAL.EDIT'] : lang['GLOBAL.SET']}
            </Button>
          }
        >
          <Form.Group className="kt-mb-0">
            <Form.Label>{`${lang['TASK.BONUS_VALUE']} (€)`}</Form.Label>
            <Form.Control
              type='number'
              step='1'
              isInvalid={bonusAmount && !bonusAmount.match(REGEXP_NUM_VALUE)}
              min={0}
              value={bonusAmount}
              onChange={() => {
                setModalSetBonusValues(event.target.value, reason);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ paddingTop: '15px' }}>{lang['TASK.BONUS_REASON']}</Form.Label>
            <Form.Control
              type='text'
              className="kt-mb-15"
              value={reason}
              onChange={() => {
                setModalSetBonusValues(bonusAmount, event.target.value);
              }}
            />
          </Form.Group>
        </Modal>

        <Modal
          title={lang['BONUSES.YOU_SURE_REMOVE']}
          show={showModalDeleteBonus}
          close={() => {
            setModalDeleteBonus(false);
          }}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setModalDeleteBonus(false);
                }}
              >{lang['GLOBAL.CANCEL']}</button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  deleteBonus(task.bonus.id);
                }}
              >{lang['GLOBAL.REMOVE']}</button>
            </>
          }
        />

        <Modal
          title={(canBonus && canSetTags) ? lang['TASK.YOU_SURE_ACCEPT_BONUS_TAGS'] : (
            canBonus ? lang['TASK.YOU_SURE_ACCEPT_BONUS'] : (
              canSetTags ? lang['TASK.YOU_SURE_ACCEPT_TAGS'] : lang['TASK.YOU_SURE_ACCEPT']))}
          show={showModalApplyTask}
          close={() => {
            setModalApplyTask(false);
          }}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setModalApplyTask(false);
                }}
              >{lang['GLOBAL.CANCEL']}</button>
              <button
                className="btn btn-success"
                onClick={finishTask}
              >{lang['GLOBAL.ACCEPT']}</button>
            </>
          }
        />

        <Modal
          show={showModalReopenTask}
          title={lang['TASK.RETURN_WORK']}
          close={() => {
            setModalReopenTask(false);
          }}
          footer={
            <>
              <Button
                variant={'secondary'}
                onClick={() => {
                  setModalReopenTask(false);
                }}
              >{lang['GLOBAL.CLOSE']}</Button>
              <Button
                variant={'danger'}
                disabled={this.isReopenTaskDisabled()}
                onClick={this.onReturnTask}
              >
                {lang['GLOBAL.RETURN']}
              </Button>
            </>
          }
        >
          <form
            onSubmit={this.onReturnTask}
          >
            <input type='submit' className='d-none' />
            <div className="kt-portlet__body kt-portlet__body--fullheight">
              {reasons.length > 0 && (
                <Form.Group>
                  <div className='row'>
                    {reasons.map((reason, index) => {
                      const checked = this.state.curReasons.includes(index);

                      return (
                        <div key={reason && index} className='col-sm-6 col-12 d-flex align-items-center'>
                          <Form.Label htmlFor={`reason_${index}`}>
                            <Form.Check
                              key={reason && index}
                              inline
                              id={`reason_${index}`}
                              label={reason[currentLang]}
                              type='checkbox'
                              checked={checked}
                              className='kt-m-0'
                              onChange={() => {
                                if (checked) {
                                  this.setState({
                                    curReasons: this.state.curReasons.filter((reason) => reason !== index),
                                  });
                                } else {
                                  this.setState({
                                    curReasons: [ ...this.state.curReasons, index ],
                                  });
                                }
                              }}
                            />
                          </Form.Label>
                        </div>
                      );
                    })}
                  </div>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label> {lang['GLOBAL.LEAVE_COMMENT']}</Form.Label>
                <HtmlEditor
                  onChange={(value) => {
                    this.setState({
                      message: value,
                    });
                  }}
                  initialValue={message || ''}
                  init={{
                    license_key: config.tinyEditorApiKey,
                    plugins: 'preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen link table charmap pagebreak nonbreaking advlist lists wordcount help charmap emoticons',
                    toolbar: 'undo redo | bold italic underline fontsizeselect numlist bullist | fontselect formatselect strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview',
                    toolbar_mode: 'sliding',
                    menubar: false,
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />
                <Form.Text>{lang['GLOBAL.LEAVE_COMMENT_PLEASE']}</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  as={DropFile}
                  multiple
                  theme='danger'
                  className='h-auto'
                  info={lang['TASK.ONE_OR_MORE_FILES']}
                  curFiles={returnFiles}
                  addFiles={(result) => {
                    this.setState({
                      returnFiles: [ ...returnFiles, ...result ],
                    });
                  }}
                  removeFile={(file) => {
                    this.setState({
                      returnFiles: filter(returnFiles, (item) => item !== file),
                    });
                  }}
                />
              </Form.Group>
              {(task.type === taskTypes.editor_checking_artist.type || task.type === taskTypes.editor_checking_designer.type) && (
                <Form.Group className="row">
                  <Form.Label className="col-md-4 col-12">
                    {lang[task.type === taskTypes.editor_checking_artist.type ? 'TASK.CHOOSE_ARTIST_DEADLINE' : 'TASK.CHOOSE_COLORIST_DEADLINE']}
                  </Form.Label>
                  <div className="col-md-8 col-12">
                    <MyDatePicker
                      selected={this.state.selectedDeadline || formatStringToDate(getTaskDeadline(task))}
                      minDate={new Date()}
                      maxDate={formatStringToDate(task.project.deadline_to)}
                      onChange={(date) => {
                        if (date) {
                          this.setState({
                            selectedDeadline: date,
                          });
                        }
                      }
                      }
                    />
                  </div>
                </Form.Group>
              )}
              {checkRole([ taskTypes[task.type].role ], [ ...groupsRoles.testers.all ]) && <Form.Group>
                <Form.Label> {lang['TASK.TIME_TRACKING']}</Form.Label>
                <Form.Control
                  value={testingTime}
                  type="number"
                  min="0"
                  onChange={(event) => {
                    this.setState({
                      testingTime: event.target.value,
                    });
                  }}
                />
              </Form.Group>}
            </div>
          </form>
        </Modal>
        <TagSystemPictureTagsModal
          showModalTagSystemPictureTags={showModalTagSystemPictureTags}
          setModalTagSystemPictureTags={setModalTagSystemPictureTags}
          picture={tagSystemPicture}
        />
      </>
    );
  }
}

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userId: store.user.user.id,
    userRoles: store.user.roles,
    currentLang: store.language.cur,
    categories: store.categories,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

TaskInfoModals.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(TaskInfoModals);
