import { Form } from 'react-bootstrap';
import React from 'react';
import SelectHashtag from '../../../widgets/SelectHashtag';
import { getCheckedValue } from '../../../utils/getCheckedValue';
import SelectCategories from '../../../widgets/SelectCategories';

/**
 * Builds an input with a drop-down list for the field, if this is a selection of tags, it substitutes the tag selector
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} variants
 * @param {any} value
 * @param {Function} onChange
 * @param {any} project
 * @param {boolean} required
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function (field, variants, value = null, onChange = null, project = null, required) {
  if (!project) {
    project = this.state;
  }
  const { lang } = this.props;

  const cur = (value === null ? project[field.key] : value) || [];

  const _onChange = (val) => {
    if (onChange === null) {
      this.setState({
        project: {
          ...project,
          [field.key]: val,
        },
      });
    } else {
      onChange(val);
    }
  };

  return (
    <Form.Group
      as={Form.Row} key={field.key}
      style={{
        marginBottom: '1rem',
        ...(field.isFull ? {} : {
          maxWidth: 1024,
          marginLeft: 'auto',
          marginRight: 'auto',
        }),
      }}
    >
      <div className={field.isColumn ? 'col-12' : 'col-lg-3 col-12'}>
        <Form.Label column>
          {(lang[field.translate] || lang[`PROJECT.FIELDS.${field.key}`] || field.title || '')}
          {field.isRequired && required && (
            <span style={{ color: 'red' }}>{' *'}</span>
          )}
        </Form.Label>
      </div>
      {field.key === 'tags' && (
        <div className={field.isColumn ? 'col-12 d-flex flex-column' : 'col-lg-9 col-12 d-flex flex-column'}>
          <SelectHashtag
            isInvalid={this.state.showInvalid && field.isRequired && required && !getCheckedValue(value === null ? project[field.key] : value)}
            value={cur}
            onChange={_onChange}
          />
          {field.muted && (
            <Form.Text className="text-muted">
              {field.muted}
            </Form.Text>
          )}
          <Form.Control.Feedback type="invalid">
            {lang['GLOBAL.FIELD_REQUIRED']}
          </Form.Control.Feedback>
        </div>
      )}
      {field.key === 'categories' && (
        <div className={field.isColumn ? 'col-12 d-flex flex-column' : 'col-lg-9 col-12 d-flex flex-column'}>
          <SelectCategories
            filter={(category) => this.props.categories.findIndex(
              () => (category.value === project.category_id)
            ) === -1}
            isInvalid={this.state.showInvalid && field.isRequired && required && !getCheckedValue(value === null ? project[field.key] : value)}
            value={cur}
            onChange={_onChange}
          />
        </div>
      )}
    </Form.Group>
  );
}
