import axiosApiInstance from '../../../requests/utils/api';
import { actionsNotifications } from '../../notification';
import { NOTIFICATIONS_TYPES } from '../../../widgets/Notification';
import { tagSystemPath } from '../helpers';



const actions = {
  LOADING: 'LOADING_PICTURE',
  LOADING_STRUCTURE: 'LOADING_STRUCTURE',
  GET_PICTURE: 'GET_PICTURE',
  SET_PICTURE: 'SET_PICTURE',
  SET_PICTURE_STRUCTURE: 'SET_PICTURE_STRUCTURE',
  UPDATE_PICTURE_STRUCTURE: 'UPDATE_PICTURE_STRUCTURE',
  setLoading: (payload, type = actions.LOADING) => ({
    type,
    payload,
  }),
  setPicture: (payload) => ({
    type: actions.SET_PICTURE,
    payload,
  }),
  setPictureStructure: (payload) => ({
    type: actions.SET_PICTURE_STRUCTURE,
    payload,
  }),
  getPictureStructures: () => async (dispatch, getState) => {
    try {
      dispatch(actions.setLoading(true, actions.LOADING_STRUCTURE));
      const URL = `${tagSystemPath}/pictures/structures`;
      const data = await axiosApiInstance.get(URL);

      if (data.data.structure) {
        dispatch(actions.setPictureStructure(data.data.structure));
      }

      return data.data.structure;
    } catch {
      const store = getState();

      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GET_PICTURE_STRUCTURE'], NOTIFICATIONS_TYPES.error));
    } finally {
      dispatch(actions.setLoading(false, actions.LOADING_STRUCTURE));
    }
  },
  updatePictureStructures: (payload) => ({
    type: actions.UPDATE_PICTURE_STRUCTURE,
    payload,
  }),
  getPicture: (projectId) => async (dispatch, getState) => {
    try {
      dispatch(actions.setLoading(true));
      const URL = `${tagSystemPath}/pictures/project/${projectId}`;
      const data = await axiosApiInstance.get(URL);

      if (data.data.picture) {
        let picture = data.data.picture;

        if (picture.status && picture.status === 404) {
          picture = {
            'image_id': null,
            'project_id': projectId,
            'url': null,
            'content': [],
          };
        }
        dispatch(actions.setPicture(picture));
      }
    } catch {
      const store = getState();

      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GET_PICTURE'], NOTIFICATIONS_TYPES.error));
    } finally {
      dispatch(actions.setLoading(false));
    }
  },

  updatePicture: (newPicture) => async (dispatch, getState) => {
    const store = getState();

    dispatch(actions.setLoading(true));

    try {
      const URL = `${tagSystemPath}/pictures`;

      const picture = await axiosApiInstance.post(URL, newPicture);

      dispatch(actions.setPicture(picture?.data));
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_PICTURE_EDIT'], NOTIFICATIONS_TYPES.success));
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_PICTURE_EDIT'], NOTIFICATIONS_TYPES.error));
      }
      throw error;
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
};

export default actions;
