import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';
import Modal from '../../../widgets/Modal';
import TaskHeaderTable from '../../tasks/list/TaskHeaderTable';
import TaskItemTable from '../../tasks/list/TaskItemTable';
import taskTypes from '../../tasks/taskTypes';
import { TASK_BONUS_STATUS } from '../../../../const/tasks';
import urlPageFreelancerDetails from '../../../urls/urlPageFreelancerDetails';
import StatLabel from '../../../components/Stat/StatLabel';
import Stat from '../../../components/Stat/Stat';
import StatNumber from '../../../components/Stat/StatNumber';
import TableStat from '../../../components/TableStat/TableStat';
import { TESTER_PRICE } from '../../../../const/project';
import TimeTrackerModal from '../../billing/components/TimeTreckerModal';
import { lastAllowedDateToAcceptTesterBonus } from '../../../../const/date';
import BonusForm from './BonusForm';



const today = new Date();
const curMonth = new Date(today.getFullYear(), today.getMonth());

const propTypes = {
  lang: PropTypes.object.isRequired,
  bonuses: PropTypes.array,
  createBonus: PropTypes.func,
  updateBonus: PropTypes.func,
  bonusStatus: PropTypes.func,
  deleteBonus: PropTypes.func,
  role: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  filterName: PropTypes.string,
  currentUserId: PropTypes.number,
  showListTasks: PropTypes.bool,
  setModalShowListTasks: PropTypes.func,
  changeBonusStatus: PropTypes.func,
  setUserId: PropTypes.func,
  userRoles: PropTypes.array.isRequired,
  updateTesterTrackingTime: PropTypes.func,
  approveTester: PropTypes.func,
  lastEditedTaskId: PropTypes.any,
};

const STATUS_COLORS = {
  black: '#595d6e',
  red: '#ff0000',
  green: '#24b744',
};

class BonusesList extends React.Component {
  state = {
    filterName: '',
    deleteBonus: '',
    showModalChangeBonusStatus: false,
    showModalEditBonus: false,
    showModalCreateBonus: false,
    showModalAbandonBonus: false,
    showModalApplyBonus: false,
    showModalTimeTracker: false,
    showModalApproveTester: false,
    selectedBonus: this.props.bonuses.find((bonus) => bonus.user.id === this.props.currentUserId) || {},
    selectedTask: {},
    currentTask: {
      testing_time: 1,
    },
  };

  onChangeTrackingTime = async (time, task) => {
    await this.props.updateTesterTrackingTime(task.id, time);
    await this.onCloseTimeTrackerModal();
  };

  onClickRow = (task) => {
    this.setState({
      showModalTimeTracker: true,
      currentTask: task,
    });
  };

  onCloseTimeTrackerModal = () => {
    this.setState({
      showModalTimeTracker: false,
      currentTask: {
        testing_time: 1,
      },
    });
  };

  getTaskColor = (data) => {
    const amountThreshold = 50;
    const isApproved = this.isApproved(data.bonus);
    const bonusAmount = data.bonus.tasks.reduce(((acc, curValue) => curValue.bonus.status === TASK_BONUS_STATUS.APPLIED ? acc + (curValue.bonus.amount || 0) : acc), 0);
    const isLeadEditor = checkRole(this.props.userRoles, [ roles['lead-coloring-editor'].key ]);

    let amount = data.amount;

    if (checkRole(data.bonus.user.roles, roles['coloring-artist'].key) && checkRole(data.bonus.user.roles, roles['coloring-designer'].key)) {
      amount = data.bonus.tasks
        .filter((task) => task.type === taskTypes.artist_drawing.type || task.type === taskTypes.designer_coloring.type)
        .reduce(((acc, curValue) => acc + Number(curValue.price)), 0);
    }

    if (amount + bonusAmount < amountThreshold) {
      return STATUS_COLORS.red;
    }

    if ((amount + bonusAmount) > amountThreshold && isLeadEditor) {
      return STATUS_COLORS.green;
    }

    if (amount + bonusAmount >= amountThreshold && isApproved) {
      return STATUS_COLORS.green;
    }

    return STATUS_COLORS.black;
  };

  isDisabledCreateBonus = () => {
    const date = new Date();

    return checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key ])
      && date.getDate() > lastAllowedDateToAcceptTesterBonus;
  };

  getBonusColor = (data) => {
    const isReadyToApprove = (bonus) => {
      const isNotApprovedBonusExists = bonus?.tasks?.filter((task) => task.type === this.props.type)
        .find((task) => {
          return task.bonus.status === TASK_BONUS_STATUS.WAITING;
        });

      return typeof isNotApprovedBonusExists === 'undefined';
    };
    const amountThreshold = 50;
    const isBonusesApproved = isReadyToApprove(data.bonus);
    const bonusAmount = data.bonus.tasks.reduce(((acc, curValue) => curValue.bonus.status === TASK_BONUS_STATUS.APPLIED ? acc + (curValue.bonus.amount || 0) : acc), 0);

    let amount = data.amount;

    if (checkRole(data.bonus.user.roles, roles['coloring-artist'].key) && checkRole(data.bonus.user.roles, roles['coloring-designer'].key)) {
      amount = data.bonus.tasks
        .filter((task) => task.type === taskTypes.artist_drawing.type || task.type === taskTypes.designer_coloring.type)
        .reduce(((acc, curValue) => acc + Number(curValue.price)), 0);
    }


    if (amount + bonusAmount < amountThreshold) {
      return STATUS_COLORS.red;
    }

    if (amount >= amountThreshold && bonusAmount === 0 && !isBonusesApproved) {
      return STATUS_COLORS.red;
    }

    if (amount >= amountThreshold && bonusAmount === 0) {
      return STATUS_COLORS.black;
    }

    if ((amount + bonusAmount) >= amountThreshold && bonusAmount > 0 && !isBonusesApproved) {
      return STATUS_COLORS.red;
    }

    if ((amount + bonusAmount) >= amountThreshold && bonusAmount > 0 && isBonusesApproved) {
      return STATUS_COLORS.green;
    }

    return STATUS_COLORS.black;
  };

  render () {
    const { lang, bonuses, type, setModalShowListTasks, showListTasks, setUserId, currentUserId, role } = this.props;

    const {
      deleteBonus,
      showModalAbandonBonus,
      showModalApplyBonus,
      showModalCreateBonus,
      showModalEditBonus,
      showModalApproveTester,
    } = this.state;

    const calcTasksWithBonus = (arrayData, status) => {
      return arrayData.reduce(((acc, curValue) => curValue.bonus.status === status ? acc + 1 : acc), 0);
    };

    const calcImgTypeValue = (bonus, imgType) => bonus.tasks.filter((task) => task.image_type === imgType && task.type === type).length;

    return (
      <>
        {bonuses.filter((bonus) => {
          return `${bonus.user.username}`.toLowerCase().indexOf(this.props.filterName.toLowerCase()) !== -1
            && checkRole([ roles[this.props.role].key ], bonus.user.roles) && bonus.tasks.some((task) => task.type === this.props.type);
        }).map((bonus) => {

          const filteredTasks = bonus.tasks.filter((task) => task.type === taskTypes[type].type);

          const testingTime = checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key, roles.administrator.key ])
            ? (filteredTasks.reduce(((acc, curValue) => acc + curValue.testing_time), 0) / 60)
            : 0;
          const tasksSum = taskTypes.device_testing.type === this.props.type
            ? testingTime * TESTER_PRICE.PRICE
            : filteredTasks.reduce(((acc, curValue) => acc + Number(curValue.price)), 0);
          const tasksBonusesSum = filteredTasks.reduce((acc, curValue) => curValue.bonus.amount + acc, 0);
          const waitingBonuses = calcTasksWithBonus(filteredTasks, TASK_BONUS_STATUS.WAITING);
          const approvedBonuses = calcTasksWithBonus(filteredTasks, TASK_BONUS_STATUS.APPLIED);
          const canceledBonuses = calcTasksWithBonus(filteredTasks, TASK_BONUS_STATUS.CANCELED);

          const getTasksAmount = () => {
            return [
              role !== roles['coloring-designer'].key && {
                name: role !== roles['coloring-designer'].key ? '' : lang['GLOBAL.TOTAL'],
                value: filteredTasks.length,
              }, role === roles['coloring-designer'].key && {
                name: lang['PROJECT.FIELDS.type.sharp'],
                value: calcImgTypeValue(bonus, 'sharp'),
              }, role === roles['coloring-designer'].key && {
                name: lang['PROJECT.FIELDS.type.blend'],
                value: calcImgTypeValue(bonus, 'blend'),
              }, role === roles['coloring-designer'].key && {
                name: lang['PROJECT.FIELDS.type.cargo'],
                value: calcImgTypeValue(bonus, 'cargo'),
              },
            ];
          };

          const getBonusesAwards = () => {
            return [
              {
                name: lang['BONUSES.WAITING'],
                value: waitingBonuses,
              }, {
                name: lang['BONUSES.APPROVED'],
                value: approvedBonuses,
              }, {
                name: lang['BONUSES.CANCELED'],
                value: canceledBonuses,
              },
            ];
          };

          const getPaymentHistory = () => {
            return bonus.user.comboHistory.map((month) => {
              let count = curMonth.getMonth() - month.date.getMonth();

              // eslint-disable-next-line fp/no-loops
              while (count < 0) {
                count += 12;
              }

              let msg = `${count} ${lang['BONUSES.MONTH']}`;

              if (count === 0) {
                msg = lang['BONUSES.MONTH_CUR'];
              } else if (count === 1) {
                msg = lang['BONUSES.MONTH_PAST'];
              }

              const paymentHistory = month.hasOwnProperty('paymentAmount') ? `${(Number(month.paymentAmount)).toFixed(2)}€` : '---';
              const amount = month.hasOwnProperty('amount') ? `${month.amount.toFixed(2)}€` : '---';

              return {
                name: msg,
                value: `${paymentHistory} / ${amount}`,
              };
            });
          };

          return (
            <div key={bonus.user.id} className="kt-portlet">
              <div className="kt-portlet__body">
                <div className="kt-widget kt-widget--user-profile-3">
                  <div className="kt-widget__top">
                    <div className="kt-widget__content" style={{ paddingLeft: 'unset', maxWidth: 'unset' }}>
                      <div className="kt-widget__head justify-content-between">
                        <Link
                          to={urlPageFreelancerDetails({ freelancerId: bonus.user.id })}
                          className="kt-widget__username"
                        >
                          {`${bonus.user.username}`}
                          {bonus.user.inactive ? (
                            <i className="fa fa-ban kt-font-danger" title={lang['CUSTOMER.LOCKED']} />
                          ) : (
                            <i className="flaticon2-correct kt-font-success" title={lang['CUSTOMER.ACTIVE']} />
                          )}
                        </Link>
                        <div className="kt-widget__action">
                          <Button
                            className='kt-ml-5'
                            variant="warning"
                            size="sm"
                            onClick={() => {
                              setUserId(bonus.user.id);
                              setModalShowListTasks(true);
                              this.setState({
                                selectedBonus: bonus,
                              });
                            }}
                          >
                            {lang['BONUSES.TASKS_AWARDS']}
                          </Button>
                        </div>
                      </div>
                      <div className="kt-widget__info">
                        <div className="kt-widget__desc">
                          <hr />
                          <div
                            className="text-center d-flex justify-between flex-wrap"
                          >
                            <div>
                              <Stat>
                                <StatLabel>{lang['BONUSES.TASKS']}</StatLabel>
                                <StatNumber
                                  prefix="€"
                                  color={this.getTaskColor({
                                    amount: tasksSum,
                                    bonusAmount: tasksBonusesSum,
                                    waitingBonuses,
                                    approvedBonuses,
                                    canceledBonuses,
                                    bonus,
                                  })}
                                >
                                  {tasksSum.toFixed(2)}
                                </StatNumber>
                              </Stat>
                            </div>
                            <div>
                              <Stat>
                                <StatLabel>{lang['BONUSES.AWARDS']}</StatLabel>
                                <StatNumber
                                  prefix="€"
                                  color={this.getBonusColor({
                                    amount: tasksSum,
                                    bonusAmount: tasksBonusesSum,
                                    waitingBonuses,
                                    approvedBonuses,
                                    canceledBonuses,
                                    bonus,
                                  })}
                                >
                                  {tasksBonusesSum.toFixed(2)}
                                </StatNumber>
                              </Stat>
                            </div>
                            <div>
                              <TableStat
                                title={role === roles['coloring-designer'].key ? `${lang['BONUSES.TASKS_AMOUNT']}: ${filteredTasks.length}` : `${lang['BONUSES.TASKS_AMOUNT']}`}
                                data={getTasksAmount()}
                              />
                            </div>
                            {(this.props.type === 'device_testing' && checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key, roles.administrator.key ])) && <div>
                              <TableStat
                                title={lang['BONUSES.TIME']}
                                data={[ {
                                  name: '',
                                  value: `${testingTime.toFixed(2)} ч`,
                                } ]}
                              />
                            </div>}
                            <div>
                              <TableStat
                                title={lang['BONUSES.AWARDS']}
                                data={getBonusesAwards()}
                              />
                            </div>
                            <div>
                              <TableStat
                                title={`${lang['BONUSES.PAYMENT_HISTORY']} / ${lang['BONUSES.HISTORY'].replace('history', '').replace('История', '')}`}
                                data={getPaymentHistory()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
        }
        <Modal
          show={!deleteBonus && showListTasks}
          close={() => {
            setModalShowListTasks(false);
          }}
          full
          bodyStyle={{ overflowY: 'auto' }}
          footer={
            <>
              {checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key, roles.administrator.key ]) && (
                <Button
                  disabled={!this.isReadyToApprove(this.state.selectedBonus) || this.isApproved(this.state.selectedBonus)}
                  variant={this.isReadyToApprove(this.state.selectedBonus) ? 'success' : 'warning'}
                  size="sm"
                  onClick={this.toggleModal('showModalApproveTester', true)}
                >
                  {this.isApproved(this.state.selectedBonus) ? lang['GLOBAL.APPROVED'] : lang['GLOBAL.APPROVE']}
                </Button>
              )}
              <Button
                variant='secondary'
                size='sm'
                onClick={() => {
                  setModalShowListTasks(false);
                }}
              >
                {lang['GLOBAL.CLOSE']}
              </Button>
            </>
          }
        >
          <div className='mw-100 overflow-auto'>
            <Table
              striped
              className='vertical-align-middle text-center kt-font-md'
              size="sm"
            >
              <TaskHeaderTable isColoring isListBonuses />
              <tbody>
                {currentUserId && bonuses.find((bonus) => bonus.user.id === currentUserId).tasks
                  .filter((item) => item.type === taskTypes[type].type).map((task) => {
                    return (
                      <TaskItemTable
                        onClickRow={this.onClickRow}
                        key={task.id}
                        task={task}
                        type={this.props.type}
                        lastEditedTaskId={this.props.lastEditedTaskId}
                        isColoring
                        toProject
                        isListBonuses
                        isNotEditable={(checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key, roles.administrator.key ]) && this.isApproved(this.state.selectedBonus))}
                        showExecutor={false}
                        isBonusCreateDisabled={this.isDisabledCreateBonus()}
                        setCurrentTask={this._setCurrentTask}
                        setModalCreateBonus={this.toggleModal('showModalCreateBonus', true)}
                        setModalEditBonus={this.toggleModal('showModalEditBonus', true)}
                        setModalApplyBonus={this.toggleModal('showModalApplyBonus', true)}
                        setModalAbandonBonus={this.toggleModal('showModalAbandonBonus', true)}
                      />
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Modal>
        {checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key, roles.administrator.key ]) &&
          <TimeTrackerModal
            task={this.state.currentTask}
            onSubmit={this.onChangeTrackingTime}
            onClose={this.onCloseTimeTrackerModal}
            isOpen={this.state.showModalTimeTracker}
          />}
        <Modal
          title={lang['BONUSES.YOU_SURE_REMOVE']}
          show={!!deleteBonus}
          close={() => {
            this.setState({
              deleteBonus: '',
            });
          }}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.setState({
                    deleteBonus: '',
                  });
                }}
              >{lang['GLOBAL.CANCEL']}</button>
              <button
                className="btn btn-danger"
                onClick={async () => {
                  this.props.deleteBonus(deleteBonus);
                }}
              >{lang['GLOBAL.REMOVE']}</button>
            </>
          }
        />

        <Modal
          title={lang['BONUSES.YOU_SURE_ABANDON']}
          show={!!showModalAbandonBonus}
          close={this.toggleModal('showModalAbandonBonus', false)}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={this.toggleModal('showModalAbandonBonus', false)}
              >
                {lang['GLOBAL.CANCEL']}
              </button>
              <button
                className="btn btn-danger"
                onClick={async () => {
                  this.props.changeBonusStatus(this.state.selectedTask?.id, this.state.selectedTask?.bonus?.id, this.state.selectedTask?.bonus?.status);
                }}
              >
                {lang['GLOBAL.APPLY']}
              </button>
            </>
          }
        />

        <Modal
          title={lang['BONUSES.YOU_SURE_APPLY']}
          show={showModalApplyBonus}
          close={this.toggleModal('showModalApplyBonus', false)}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={this.toggleModal('showModalApplyBonus', false)}
              >
                {lang['GLOBAL.CANCEL']}
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  this.props.changeBonusStatus(this.state.selectedTask?.id, this.state.selectedTask?.bonus?.id, this.state.selectedTask?.bonus?.status);
                }}
              >
                {lang['GLOBAL.APPLY']}
              </button>
            </>
          }
        />

        <Modal
          title={lang['TESTERS.YOU_SURE_APPLY']}
          show={showModalApproveTester}
          close={this.toggleModal('showModalApproveTester', false)}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={this.toggleModal('showModalApproveTester', false)}
              >
                {lang['GLOBAL.CANCEL']}
              </button>
              <button
                className="btn btn-success"
                onClick={this.approveTester}
              >
                {lang['GLOBAL.APPLY']}
              </button>
            </>
          }
        />

        <Modal
          title={lang['BONUSES.EDITING']}
          show={showModalEditBonus}
          close={this.toggleModal('showModalEditBonus', false)}
          showFooter={false}
        >
          <BonusForm
            bonus={this.state.selectedTask?.bonus}
            onSubmit={(bonus) => {
              this.props.updateBonus({
                amount: bonus.amount,
                bonusId: this.state.selectedTask?.bonus?.id,
                taskId: this.state.selectedTask?.id,
                reason: bonus.reason,
                bonusStatus: this.state.selectedTask?.bonus?.status,
              });
            }}
            onCancel={this.toggleModal('showModalEditBonus', false)}
          />
        </Modal>

        <Modal
          title={lang['BONUSES.CREATE']}
          show={showModalCreateBonus}
          close={this.toggleModal('showModalCreateBonus', false)}
          showFooter={false}
        >
          <BonusForm
            onSubmit={(bonus) => {
              this.props.createBonus(bonus.amount, this.state.selectedTask?.id, bonus.reason, this.state.selectedTask?.bonus?.status);
            }}
            onCancel={this.toggleModal('showModalCreateBonus', false)}
          />
        </Modal>
      </>);
  }

  isReadyToApprove = (bonus) => {
    const isNotApprovedBonusExists = bonus?.tasks?.find((task) => {
      return task.bonus.status === TASK_BONUS_STATUS.WAITING;
    });
    const isTestingTimeSet = bonus.tasks?.find((task) => {
      return task.testing_time === 0;
    });

    return typeof isNotApprovedBonusExists === 'undefined' && typeof isTestingTimeSet === 'undefined';
  };

  isApproved = (bonus) => {
    if (!bonus.hasOwnProperty('tasks')) {
      return false;
    }

    const isApproved = bonus.tasks.find((task) => {
      return task.price === 0;
    });

    return typeof isApproved === 'undefined';
  };

  approveTester = async () => {
    await this.props.approveTester(this.state.selectedBonus.user.id);
    this.toggleModal('showModalApproveTester', false);
    this.props.setModalShowListTasks(false);
  };

  toggleModal = (modal, isActive) => {
    return () => {
      this.setState({
        [modal]: isActive,
      });
    };
  };

  _setCurrentTask = (task) => {
    this.setState({
      selectedTask: task,
    });
  };
}

BonusesList.propTypes = propTypes;

BonusesList.defaultProps = {
  lastEditedTaskId: '',
  approveTester: () => {},
};

export default BonusesList;
