import React, { useEffect, useReducer } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import Tabs from '../../widgets/Tabs';
import Preload from '../../widgets/Preload';
import axiosApiInstance from '../../requests/utils/api';
import { TASK_BONUS_STATUS } from '../../../const/tasks';
import billingActions from '../../store/billing/actions';
import { notification } from '../../requests/notifications';
import checkRole from '../../utils/checkRole';
import roles from '../customers/roles/roles';
import usePrevious from '../../hooks/usePrevious';
import { formatToPrettyDate } from '../../utils/formats';
import { isXFLOW } from '../../utils/isFabros';
import { actionsNotifications } from '../../store/notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';
import getTesterSalaryByTask from '../../utils/getTesterSalaryByTask';
import BillingItemModal from './BillingItemModal';
import BillingTable from './components/BillingTable';
import TimeTrackerModal from './components/TimeTreckerModal';
import PayoutsTable from './components/PayoutsTable';
import BillingStatistics from './components/BillingStatistics';



const initialState = {
  invoiceTasks: [],
  invoiceDate: {
    from: null,
    to: null,
  },
  invoiceStatus: '',
  showBillingItemModal: false,
  isTimeTrackerModalOpen: false,
  currentTask: {
    testing_time: 1,
  },
  payoutLoading: false,
  isPayoutLinkReceived: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAYOUT_LOADING':
      return { ...state, payoutLoading: action.payload };
    case 'SET_PAYOUT_LINK':
      return { ...state, isPayoutLinkReceived: action.payload };
    case 'SHOW_BILLING_MODAL':
      return { ...state, showBillingItemModal: action.payload };
    case 'SET_INVOICE_DATE':
      return { ...state, invoiceDate: action.payload };
    case 'SET_INVOICE_STATUS':
      return { ...state, invoiceStatus: action.payload };
    case 'SET_INVOICE_TASKS':
      return { ...state, invoiceTasks: action.payload };
    case 'SET_CURRENT_TASK':
      return { ...state, currentTask: action.payload };
    case 'SHOW_TIME_TRACKER_MODAL':
      return { ...state, isTimeTrackerModalOpen: action.payload };
    default:
      return state;
  }
};

const Billing = () => {
  const dispatchRedux = useDispatch();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { user, userRoles, currentRole, multipleRoles, lang, stat, invoices, tasks, prevMonthTasks, loading } = useSelector(({ user, language, billing }) => ({
    userRoles: user.roles,
    user: user.user,
    currentRole: user.currentRole,
    multipleRoles: user.multipleRoles,
    lang: language.lang,
    stat: billing.stat,
    invoices: billing.invoices,
    tasks: billing.tasks,
    prevMonthTasks: billing.prevMonthTasks,
    loading: billing.LOAD,
  }), shallowEqual);
  const prevProps = usePrevious({
    currentRole,
  });

  useEffect(async () => {
    await dispatchRedux(billingActions.fetchPrevMonthBilling());
    await dispatchRedux(billingActions.fetchBilling());
  }, []);

  useEffect(async () => {
    if (multipleRoles.length > 1 && prevProps?.currentRole !== currentRole) {
      await dispatchRedux(billingActions.fetchPrevMonthBilling());
      await dispatchRedux(billingActions.fetchBilling());
    }
  }, [ multipleRoles, currentRole ]);

  const getInvoiceTasks = async (invoiceId) => {
    try {
      const response = await axiosApiInstance.get(`invoices/${invoiceId}/tasks`);
      const payload = response.data.tasks;

      dispatch({ type: 'SET_INVOICE_TASKS', payload });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      notification('BILLING.ERROR_SET_INVOICE_TASK', 'error');
    }
  };

  const onClickRow = (task) => {
    dispatch({ type: 'SHOW_TIME_TRACKER_MODAL', payload: true });
    dispatch({ type: 'SET_CURRENT_TASK', payload: task });
  };

  const onClickPayoutsRow = async (bill) => {
    dispatch({ type: 'SET_INVOICE_DATE',
      payload: {
        from: formatToPrettyDate(bill.from),
        to: formatToPrettyDate(bill.to),
      } });
    dispatch({ type: 'SET_INVOICE_STATUS', payload: bill.status });
    await getInvoiceTasks(bill.id);
    dispatch({ type: 'SHOW_BILLING_MODAL', payload: true });
  };

  const onCloseTimeTrackerModal = () => {
    dispatch({ type: 'SHOW_TIME_TRACKER_MODAL', payload: false });
    dispatch({
      type: 'SET_CURRENT_TASK',
      payload: {
        testing_time: 1,
      },
    });
  };

  const onChangeTrackingTime = async (time, task) => {
    try {
      const successStatus = 200;
      const res = await axiosApiInstance.post(`tasks/${task.task_id}/tester`, {
        time,
      });

      if (res.status === successStatus) {
        const updateTasks = (item) => {
          if (item.task_id === task.task_id && item.project_id === task.project_id) {
            return {
              ...item,
              testing_time: time,
            };
          }

          return item;
        };

        if (task.isPrevMonth) {
          dispatchRedux(billingActions.updateTrackingTimeForPrevMonth(
            prevMonthTasks.map(updateTasks)
          ));
        } else {
          dispatchRedux(billingActions.updateTrackingTime(
            tasks.map(updateTasks)
          ));
        }
      }
      onCloseTimeTrackerModal();
    } catch {
      notification('TASK.ERROR_UPDATE_TRACKING_TIME', 'error');
    }
  };

  const isTester = checkRole(userRoles, [ roles['coloring-tester'].key ]);
  const isExecutor = checkRole(userRoles, [ roles['coloring-tester'].key, roles['coloring-artist'].key, roles['coloring-designer'].key ]);

  const getBalance = () => {
    const summaryTasksAmount = tasks.reduce((acc, item) => acc + parseFloat(item.price), 0);
    const summaryBonusAmount = tasks.reduce((acc, item) => item.bonus_status === TASK_BONUS_STATUS.APPLIED ? acc + parseFloat(item.bonus_amount) : acc, 0);

    if (isTester) {
      const balance = tasks.reduce((acc, item) => (acc + parseFloat(getTesterSalaryByTask(item.testing_time))), 0);

      return `${(balance + summaryBonusAmount).toFixed(2)}€`;
    }
    return `${(summaryTasksAmount + summaryBonusAmount).toFixed(2)}€`;
  };

  const withdrawMoney = async () => {
    dispatch({ type: 'SET_PAYOUT_LOADING', payload: true });
    const response = await axiosApiInstance.get(`users/${user?.id}/payouts`);

    dispatch({ type: 'SET_PAYOUT_LOADING', payload: false });
    dispatch({ type: 'SET_PAYOUT_LINK', payload: true });

    if (response?.data?.redirect_url) {
      window.open(response?.data?.redirect_url, '_self');
    } else {
      dispatchRedux(actionsNotifications.add(
        lang['BILLING.RETRY_WITHDRAW_TIME_LIMIT_ERROR'],
        NOTIFICATIONS_TYPES.error
      ));
    }
  };

  const isWithdrawDisabled = () => {
    const unpaidInvoice = invoices.find((invoice) => invoice.status === 0);
    const invoiceAmount = unpaidInvoice?.price || 0;
    const minAmountForWithdraw = 0;

    return invoiceAmount < minAmountForWithdraw || !unpaidInvoice?.is_ready_for_payment;
  };

  return (
    <>
      {loading || state.payoutLoading ? (
        <Preload />
      ) : (
        <>
          <SubHeader
            title={lang['CUSTOMER.FINANCES_INFO']}
            toolbar={isXFLOW && isExecutor ? (
              <Button
                variant="primary"
                onClick={withdrawMoney}
                disabled={isWithdrawDisabled()}
              >
                {lang['BILLING.WITHDRAW']}
              </Button>
            ) : null}
          />
          <BillingStatistics balance={getBalance()} finishedTasks={stat.tasks.finished} payableAmount={invoices.find((invoice) => invoice.status === 0)?.price || 0} />
          <div className="kt-portlet">
            <Tabs
              title={lang['BILLING.FINANCE_STATISTIC']}
              tabs={[
                {
                  title: lang['BILLING.READY_TO_PAY_CUR_MONTH'],
                  icon: 'flaticon-multimedia',
                  body: () => {
                    return (
                      <BillingTable
                        items={tasks}
                        onClickRow={onClickRow}
                      />
                    );
                  },
                },
                {
                  title: lang['BILLING.READY_TO_PAY_PREV_MONTH'],
                  icon: 'flaticon-multimedia',
                  body: () => {
                    return (
                      <BillingTable
                        items={prevMonthTasks}
                        onClickRow={onClickRow}
                        isPrevMonth
                      />
                    );
                  },
                },
                {
                  title: lang['MENU.BILLING'],
                  icon: 'flaticon-cogwheel-2',
                  body: () => {
                    return (
                      <PayoutsTable
                        items={invoices}
                        onClickRow={onClickPayoutsRow}
                      />
                    );
                  },
                },
              ]}
            />
          </div>
        </>
      )}
      <BillingItemModal
        showBillingItemModal={state.showBillingItemModal}
        setBillingItemModal={(payload) => dispatch({ type: 'SHOW_BILLING_MODAL', payload })}
        data={state.invoiceTasks}
        invoiceDate={state.invoiceDate}
        invoiceStatus={state.invoiceStatus}
      />
      {isTester && (
        <TimeTrackerModal
          task={state.currentTask}
          onSubmit={onChangeTrackingTime}
          onClose={onCloseTimeTrackerModal}
          isOpen={state.isTimeTrackerModalOpen}
        />
      )}
    </>
  );
};

Billing.defaultProps = {
  multipleRoles: [],
  currentRole: '',
};

export default Billing;
