import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';
import taskTypes from '../taskTypes';
import MyDatePicker from '../../../widgets/DatePicker';
import { formatDateTimeToString, formatStringToDate, setDateHours } from '../../../utils/formats';
import DropFile from '../../../widgets/DropFile';
import getTaskDeadline from '../helpers/getTaskDeadline';
import { chooseExecutor, loadRefFiles } from '../../../requests/tasks';
// eslint-disable-next-line import/no-named-as-default
import notification from '../../../requests/notifications';
import { Loader } from '../../../components/Loader';



const UploadReference = ({
  executors,
  task,
  showUploadButton,
  finishTask,
  getTask,
}) => {
  const { lang, userRoles } = useSelector(({ language, user }) => ({
    lang: language.lang,
    userRoles: user.roles,
  }), shallowEqual);

  const [ selectedExecutor, setSelectedExecutor ] = useState(null);
  const [ selectedRefFiles, setSelectedRefFiles ] = useState([]);
  const [ prompt, setPrompt ] = useState(task?.project?.prompt);
  const [ selectedDeadline, setSelectedDeadline ] = useState(null);
  const [ loadingFiles, setLoadingFiles ] = useState(false);

  const onExecutorChange = (event) => {
    setSelectedExecutor(event.target.value);
  };

  const onPromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const onDeadlineChange = (date) => {
    if (date) {
      setSelectedDeadline(formatDateTimeToString(setDateHours(date)));
    }
  };

  const addRefFile = (result) => {
    setSelectedRefFiles((prevState) => [ ...prevState, ...result ]);
  };

  const removeRefFile = (file) => {
    setSelectedRefFiles(filter(selectedRefFiles, (item) => item !== file));
  };

  const onLoadRefFiles = () => {
    if (!selectedRefFiles) {
      notification('TASK.ERROR_FILES', 'error');
    } else {
      loadRefFiles(
        {
          task,
          files: selectedRefFiles,
          selectedExecutor,
        },
        () => {
          getTask();
        },
        () => {
          setSelectedRefFiles([]);
        },
        setLoadingFiles
      );
    }
  };

  const getSelectedTaskDeadline = (date) => {
    if (date && !selectedDeadline) {
      setSelectedDeadline(date);

      return formatStringToDate(date);
    }

    if (selectedDeadline) {
      return formatStringToDate(selectedDeadline);
    }

    return false;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    chooseExecutor(
      {
        selectedExecutor,
        task,
        selectedDeadline,
        files: selectedRefFiles,
        prompt,
        setLoader: setLoadingFiles,
      },
      finishTask
    );
  };

  return (
    <>
      {loadingFiles && <Loader centered showImg={false} />}
      <form
        className={showUploadButton ? '' : 'kt-portlet kt-bg-success kt-portlet--skin-solid'}
        onSubmit={onSubmit}
      >
        {!showUploadButton && (
          <div className="kt-portlet__head kt-portlet__head--noborder">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title font-weight-bold">
                {lang['GLOBAL.UPLOAD_REFERENCES_TITLE']}
              </h3>
            </div>
          </div>
        )}
        <div className={showUploadButton ? '' : 'kt-portlet__body kt-portlet__body--fullheight kt-pt-10'}>
          <Form.Group>
            <Form.Label className="w-100 kt-mb-15">
              {`${lang['TASK.AVAILABLE_REF_FILES']}: jpg, jpeg, png, bmp, gif, svg, webp.`}
            </Form.Label>
            <Form.Control
              as={DropFile}
              className="h-auto"
              theme="brand"
              hasCustomText
              customInnerText={lang['GLOBAL.DROP_REFERENCE_FILES']}
              showPreview={false}
              styles={{ minHeight: 110 }}
              curFiles={selectedRefFiles}
              addFiles={addRefFile}
              removeFile={removeRefFile}
            />
          </Form.Group>
          <div className="d-flex justify-content-center kt-mt-a">
            { showUploadButton && (
              <Button
                variant="light"
                className="btn-upper mx-1 mb-2 font-weight-bold"
                disabled={selectedRefFiles?.length === 0}
                type="submit"
                onClick={onLoadRefFiles}
              >
                {lang['GLOBAL.UPLOAD_REFERENCES']}
              </Button>
            )}
          </div>
          {checkRole(userRoles, [
            roles['coloring-editor'].key,
            roles['lead-coloring-editor'].key,
            roles['remote-coloring-editor'].key,
          ]) && (
            <>
              <Form.Group>
                <Form.Label column className="col-md-6 col-12">
                  <h3 className="kt-portlet__head-title font-weight-bold text-md">
                    {lang[task.type === taskTypes.artist_choosing.type ? 'TASK.CHOOSE_ARTIST' : 'TASK.CHOOSE_DESIGNER']}
                    <span style={{ color: 'red' }}>{' *'}</span>
                  </h3>
                </Form.Label>
                <Form.Control
                  as={'select'}
                  value={selectedExecutor}
                  onChange={onExecutorChange}
                >
                  <option value={''}>{lang['GLOBAL.CHOOSE']}</option>
                  {executors.map((executor) => {
                    return (
                      <option
                        key={executor.id}
                        value={executor.id}
                        disabled={executor.inactive}
                      >
                        {`${executor.username} (${executor.inactive ?
                          lang['CUSTOMER.LOCKED'].toLowerCase() :
                          lang['CUSTOMER.ACTIVE'].toLowerCase()})`}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label column className="col-md-6 col-12">
                  <h3 className="kt-portlet__head-title font-weight-bold text-md">
                    {lang[task.type === taskTypes.artist_choosing.type ? 'TASK.CHOOSE_ARTIST_DEADLINE' : 'TASK.CHOOSE_COLORIST_DEADLINE']}
                    <span style={{ color: 'red' }}>{' *'}</span>
                  </h3>
                </Form.Label>
                <MyDatePicker
                  selected={getSelectedTaskDeadline(getTaskDeadline(task))}
                  minDate={new Date()}
                  maxDate={formatStringToDate(task.project?.deadline_to)}
                  onChange={onDeadlineChange}
                />
              </Form.Group>
              <Form.Group className="">
                <Form.Label column className="col-md-6 col-12">
                  <h3 className="kt-portlet__head-title font-weight-bold text-md">
                    {lang['TASK.REFERENCE_PROMPT']}
                  </h3>
                </Form.Label>
                <Form.Control
                  as={'textarea'}
                  aria-rowcount={2}
                  value={prompt}
                  onChange={onPromptChange}
                />
              </Form.Group>
              <div className='d-flex justify-content-center kt-mt-a'>
                <Button
                  type='submit'
                  variant='light'
                  className='btn-upper mx-1 font-weight-bold'
                  disabled={!selectedExecutor || !selectedDeadline}
                >
                  {lang['GLOBAL.APPLY']}
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

UploadReference.propTypes = {
  executors: PropTypes.array.isRequired,
  task: PropTypes.object.isRequired,
  showUploadButton: PropTypes.bool,
  finishTask: PropTypes.func.isRequired,
  getTask: PropTypes.func.isRequired,
};

export default UploadReference;
