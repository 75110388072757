import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { filter } from 'lodash';
import { getTasksByIds } from '../../requests/tasks';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import Img from '../../widgets/Img';
import imgURL from '../../utils/imgURL';
import { getPreviewLg, getPreviewSm } from '../projects/getPreviewFromProject';
import priorities, { prioritiesMap } from '../projects/priorities';
import { formatDateTimeToString, formatDateToString, formatStringToDate, setDateHours } from '../../utils/formats';
import SelectHashtag from '../../widgets/SelectHashtag';
import MyDatePicker from '../../widgets/DatePicker';
import Preload from '../../widgets/Preload';
import Modal from '../../widgets/Modal';
import checkRole from '../../utils/checkRole';
import roles from '../customers/roles/roles';
import imageType from '../projects/image_type';
import { mapCategoryIdToName, mapCategoryNameToId } from '../../requests/categories';
import axiosApiInstance from '../../requests/utils/api';
import { notification } from '../../requests/notifications';
import urlPageProjectDetails from '../../urls/urlPageProjectDetails';
import { checkStringLength } from '../../utils/checker';
import { getGroup } from '../../requests/groups';
import typesUsers from '../customers/typesUsers';
import { colorComplexityLevels, contourComplexityLevels } from '../projects/projectPrices';
import HtmlEditor from '../../components/HtmlEditor';
import Chat from '../../widgets/Chat';
import { coloringProjectTypes } from '../projects/projectTypes';
import DropFile from '../../widgets/DropFile';
import SelectCategories from '../../widgets/SelectCategories';
import isCategoryInProjectCategories from '../projects/helpers/isCategoryInProjectCategories';
import config from '../../../config/app';
import getTaskByTypeFromProject from '../projects/helpers/getTaskByTypeFromProject';
import difficultyTypes from '../projects/difficultyTypes';
import hcContentTypes from '../projects/hcContentTypes';
import { getProjectDeadlineByHCReleaseDate } from '../../utils/getProjectDeadlineByHCReleaseDate';
import { containsCyrillic } from '../../utils/validators';
import isDisabledProjectContourComplexity from '../projects/helpers/isDisabledProjectContourComplexity';
import taskTypes from './taskTypes';



const propTypes = {
  lang: PropTypes.object.isRequired,
  userRoles: PropTypes.array.isRequired,
  currentRole: PropTypes.string,
  categories: PropTypes.array,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  users: PropTypes.array,
  match: PropTypes.object.isRequired,
  location: PropTypes.object,
  notification: PropTypes.func.isRequired,
};

/**
 * Task Group Acceptance Page
 */

class TasksAccept extends React.Component {
  state = {
    tasks: [],
    LOAD: true,
    showModal: false,
    executors: [],
    selectedExecutor: '',
    showInvalid: false,
    open: [],
    itemToDelete: '',
    preparedTasks: [],
    LOAD_GROUP: false,
    isEmptyRequiredField: true,
    invalidProjectTitles: [],
    initialProjectTitles: [],
    selectedRefFiles: [],
    disableLoadRefButton: false,
  };

  computed = {
    isLeadColoringEditor: () => {
      return checkRole(this.props.userRoles, [ roles['lead-coloring-editor'].key ]);
    },
    isColoringEditor: () => {
      return checkRole(this.props.userRoles, [ roles['coloring-editor'].key ]);
    },
    isManager: () => {
      return checkRole(this.props.userRoles, [ roles['content-manager'].key ]);
    },
  };

  render () {
    const { showInvalid, tasks, LOAD, LOAD_GROUP, selectedExecutor, isEmptyRequiredField, selectedRefFiles } = this.state;
    const { lang, categories } = this.props;
    const today = new Date();
    const preparedTasks = tasks.filter((item) => item.id !== this.state.itemToDelete);
    const highestContourComplexityLevel = contourComplexityLevels[contourComplexityLevels.length - 1];
    const highestColorComplexityLevel = colorComplexityLevels[colorComplexityLevels.length - 1];

    return (LOAD || LOAD_GROUP) ? (
      <Preload />
    )
      : (
        <>
          <SubHeader
            toolbar={(<div className='d-flex align-items-center'>
              {this.computed.isColoringEditor() && <div className='kt-ml-5'>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={'CHOOSE_EXECUTOR'}>
                      {lang['GLOBAL.CHOOSE_EXECUTOR']}
                    </Tooltip>
                  }
                >
                  <Form.Group className='d-flex kt-m-0 kt-ml-5 align-items-center'>
                    <Form.Control
                      size="sm"
                      as={'select'}
                      value={selectedExecutor}
                      onChange={(event) => this.handleExecutorChange(event.target.value)}
                    >
                      {this._executorsOptionsList()}
                    </Form.Control>
                  </Form.Group>
                </OverlayTrigger>
              </div>}
              <Button
                variant='success'
                className='mx-1'
                disabled={showInvalid || (!this.computed.isManager() && isEmptyRequiredField)}
                onClick={() => {
                  this.setState({
                    showModal: true,
                  });
                }}
              >
                {lang['GLOBAL.APPLY']}
              </Button>
            </div>)}
          />
          {preparedTasks.map((task, index) => {
            const project = task.project;

            const sourceFile = task.project_files?.find((item) => {
              return item.link.includes('source');
            });

            let preview = task.preview;

            if (!preview && sourceFile) {
              preview = sourceFile.link;
            }

            const referencePreview = project.reference_files?.length ? project.reference_files[0].path : null;

            return (
              <div key={task.id} className="kt-portlet">
                <div className="kt-portlet__body">
                  {preparedTasks.length > 1 && (
                    <>
                      <div className='w-100 d-flex align-items-center kt-ml-a kt-mr-a'>
                        <span className='w-100 d-block bg-dark border-bottom border-top' />
                        <h2 className='kt-pl-10 kt-pr-10 font-weight-bold kt-mb-0'>{index + 1}</h2>
                        <span className='w-100 d-block bg-dark border-bottom border-top' />
                      </div>
                      <div className="col-12 d-flex justify-content-end flex-wrap kt-mb-20">
                        <Button
                          variant="danger"
                          onClick={() => {
                            this._setUpdatedTasksList({
                              task,
                              preparedTasks,
                            });
                          }}
                        >{lang['TASK.DELETE']}</Button>
                      </div>
                    </>
                  )}
                  <div className="kt-widget kt-widget--user-profile-3">
                    <div className="kt-widget__top">
                      <div className="kt-widget__media">
                        <Img
                          src={imgURL(getPreviewSm(preview))}
                          current={{
                            id: task.id,
                            link: getPreviewLg(preview),
                          }}
                          canFull
                        />
                        <div className="kt-widget__title py-4 text-center">
                          <Link
                            target="_blank"
                            to={urlPageProjectDetails({ projectId: task.project_id })}
                            className='text-dark hover'
                          >
                            {`Project #${task.project_id}`}
                          </Link>
                        </div>
                        {referencePreview && this.computed.isManager() && (
                          <Img
                            src={imgURL(getPreviewSm(referencePreview))}
                            current={{
                              id: task.id,
                              link: getPreviewLg(referencePreview),
                            }}
                            canFull
                          />
                        )}
                      </div>
                      <div className="kt-widget__content">
                        <Form.Group className="row" title={lang['PROJECT.TITLE_LANG_ALERT']}>
                          <Form.Label column className="col-md-4 col-12">
                            {lang['PROJECT.TITLE']}
                            <span style={{ color: 'red' }}>{' *'}</span>
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <Form.Control
                              type="text"
                              id="title"
                              isInvalid={this._projectTitleIsInvalid(project.title)}
                              value={project.title || ''}
                              name="title"
                              onChange={(event) => this.handleInputChange(event, task)}
                            />
                            {this.state.invalidProjectTitles.includes(project.title?.trim()) ? (
                              <Form.Control.Feedback type="invalid" className="title-unique-validation">
                                {lang['PROJECT.TITLE_UNIQUE_VALIDATION_ERROR']}
                              </Form.Control.Feedback>
                            ) : (
                              <Form.Control.Feedback type="invalid">
                                {lang['PROJECT.TITLE_VALIDATION_ERROR']}
                              </Form.Control.Feedback>
                            )}
                          </div>
                        </Form.Group>
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['PROJECT.DESCRIPTION']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <Form.Control
                              as='textarea'
                              value={project.description || ''}
                              name="description"
                              onChange={(event) => this.handleInputChange(event, task)}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['PROJECT.COMMENT']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <HtmlEditor
                              onChange={(value) => this.handleCommentChange(value, task)}
                              initialValue={project.comment || ''}
                              init={{
                                license_key: config.tinyEditorApiKey,
                                plugins: 'preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen link table charmap pagebreak nonbreaking advlist lists wordcount help charmap emoticons',
                                toolbar: 'undo redo | bold italic underline fontsizeselect numlist bullist | fontselect formatselect strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview',
                                toolbar_mode: 'sliding',
                                menubar: false,
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                              }}
                            />
                          </div>
                          <div className="col-12 d-flex justify-content-end">
                            <Form.Check
                              type="checkbox"
                              className="pr-4"
                            >
                              <Form.Check.Label>
                                <Form.Check.Input
                                  name="comment_is_hidden"
                                  type="checkbox"
                                  checked={task.comment_is_hidden}
                                  onChange={(event) => this.handleInputChange(event, task)}
                                />
                                {lang['TAGS.IS_HIDDEN']}
                                <OverlayTrigger
                                  placement="left"
                                  overlay={(
                                    <Tooltip id="IS_HIDDE">
                                      {lang['TAGS.IS_HIDDEN_TOOLTIP']}
                                    </Tooltip>
                                  )}
                                >
                                  <i className="fa fa-info position-absolute pl-2" style={{ fontSize: '10px' }} />
                                </OverlayTrigger>
                              </Form.Check.Label>
                            </Form.Check>
                          </div>
                        </Form.Group>

                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['GLOBAL.CATEGORY']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <Form.Control
                              as="select"
                              value={mapCategoryIdToName(categories, project.options.category_id)}
                              disabled={this.computed.isColoringEditor()}
                              name="category_id"
                              onChange={(event) => this.handleInputChange(event, task)}
                            >
                              {this.props.categories.map((item) => {
                                if (isCategoryInProjectCategories(project, item)) {
                                  return;
                                }
                                return (
                                  <option key={item.id} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </Form.Group>
                        {this.computed.isManager() && (
                          <Form.Group className="row">
                            <Form.Label column className="col-md-4 col-12">
                              {lang['MENU.CATEGORIES']}
                            </Form.Label>
                            <div className="col-md-8 col-12">
                              <SelectCategories
                                filter={(category) => this.props.categories.findIndex(
                                  () => (category.value === project.options?.category_id)
                                ) === -1}
                                value={project.categories.map((category) => category.id)}
                                onChange={(ids, categories) => {
                                  const newTask = {
                                    ...task,
                                    project: {
                                      ...project,
                                      categories: categories.map((category) => ({
                                        id: category.value,
                                        name: category.label,
                                        slug: category.slug,
                                      })),
                                    },
                                  };

                                  const newList = [ ...tasks ];

                                  newList[index] = newTask;

                                  this.setState({
                                    tasks: newList,
                                  });
                                }}
                              />
                            </div>
                          </Form.Group>
                        )}
                        <Form.Group className="row">
                          <Form.Label
                            column
                            className="col-md-4 col-12"
                          >{lang['MENU.TAGS']}</Form.Label>
                          <div className="col-md-8 col-12">
                            <SelectHashtag
                              value={project.tags.map((tag) => tag.id)}
                              onChange={(ids, tags) => {
                                const newTask = {
                                  ...task,
                                  project: {
                                    ...project,
                                    tags: tags.map((tag) => ({
                                      id: tag.value,
                                      name: tag.label,
                                      slug: tag.slug,
                                    })),
                                  },
                                };

                                const newList = [ ...tasks ];

                                newList[index] = newTask;

                                this.setState({
                                  tasks: newList,
                                });
                              }}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['PROJECT.FIELDS.hc_release_date']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <MyDatePicker
                              placeholder={lang['GLOBAL.NO_CHOOSE']}
                              disabled={this.computed.isColoringEditor()}
                              selected={project.hc_release_date ? formatStringToDate(project.hc_release_date) : ''}
                              minDate={new Date()}
                              onChange={(date) => {

                                if (date) {
                                  date = formatDateTimeToString(setDateHours(date));
                                } else {
                                  date = '';
                                }

                                const newTask = {
                                  ...task,
                                  project: {
                                    ...project,
                                    hc_release_date: date,
                                  },
                                };

                                if (date) {
                                  newTask.project.deadline_to = getProjectDeadlineByHCReleaseDate(date);
                                }

                                const newList = [ ...tasks ];

                                newList[index] = newTask;

                                this.setState({
                                  tasks: newList,
                                });
                              }}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['GLOBAL.GLOBAL_DEADLINE']}
                            <span style={{ color: 'red' }}>{' *'}</span>
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <MyDatePicker
                              disabled={this.computed.isColoringEditor()}
                              isInvalid={!(project.deadline_to || '').trim()}
                              selected={project.deadline_to ? formatStringToDate(project.deadline_to) : ''}
                              minDate={new Date()}
                              minHours={formatDateToString(project.deadline_to) === formatDateToString(today)
                                ? today.getHours()
                                : 0
                              }
                              onChange={(date) => {
                                if (date) {
                                  date = formatDateTimeToString(setDateHours(date));
                                } else {
                                  date = '';
                                }

                                const newTask = {
                                  ...task,
                                  project: {
                                    ...project,
                                    deadline_to: date,
                                  },
                                };

                                const newList = [ ...tasks ];

                                newList[index] = newTask;

                                this.setState({
                                  tasks: newList,
                                  showInvalid: Boolean(!date),
                                });
                              }}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['PROJECT.FIELDS.hc_content_type']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <Form.Control
                              as="select"
                              disabled={this.computed.isColoringEditor()}
                              value={project.hc_content_type}
                              name="hc_content_type"
                              onChange={(event) => this.handleInputChange(event, task)}
                            >
                              <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
                              {hcContentTypes.map((type) => {
                                return (
                                  <option key={type} value={type}>
                                    {lang[`PROJECT.FIELDS.hc_content_type.${type}`]}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </div>
                        </Form.Group>
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['GLOBAL.PRIORITY']}
                            <span style={{ color: 'red' }}>{' *'}</span>
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <Form.Control
                              as="select"
                              id="priority"
                              isInvalid={!project.priority}
                              value={project.priority}
                              disabled={this.computed.isColoringEditor()}
                              name="priority"
                              onChange={(event) => this.handleInputChange(event, task)}
                            >
                              {priorities.map((item) => {
                                return (
                                  <option key={item} value={prioritiesMap[item]}>
                                    {lang[`GLOBAL.PRIORITY.${prioritiesMap[item]}`]}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {lang['GLOBAL.FIELD_REQUIRED']}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['PROJECT.FIELDS.type']}
                            <span style={{ color: 'red' }}>{' *'}</span>
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <Form.Control
                              as="select"
                              isInvalid={showInvalid && !project.options.type}
                              value={project.options.type}
                              name="type"
                              onChange={(event) => this.handleInputChange(event, task)}
                            >
                              {imageType.map((type) => {
                                return (
                                  <option key={type} value={type}>
                                    {lang[`PROJECT.FIELDS.type.${type}`]}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {lang['GLOBAL.FIELD_REQUIRED']}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['PROJECT.FIELDS.taps']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <Form.Control
                              type="text"
                              value={project.options.taps || '---'}
                              disabled
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['PROJECT.FIELDS.difficult']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <Form.Control
                              as="select"
                              disabled={this.computed.isColoringEditor()}
                              value={project.options.difficult || ''}
                              name="difficult"
                              onChange={(event) => this.handleInputChange(event, task)}
                            >
                              <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
                              {difficultyTypes.map((item) => {
                                return (
                                  <option key={item} value={item}>
                                    {lang[`PROJECT.FIELDS.difficult.${item}`]}
                                  </option>);
                              })}
                            </Form.Control>
                          </div>
                        </Form.Group>
                        {!this.computed.isManager() && <>
                          {[ 'coloring' ].includes(project.type) && <Form.Group className="row">
                            <Form.Label column className="col-md-4 col-12">
                              {lang['PROJECT.FIELDS.contour_complexity_level']}
                            </Form.Label>
                            <div className="col-md-8 col-12">
                              <Form.Control
                                as="select"
                                value={project.options.contour_complexity_level}
                                name="contour_complexity_level"
                                onChange={(event) => this.handleInputChange(event, task)}
                                disabled={isDisabledProjectContourComplexity(project, taskTypes.artist_drawing.type, this.props.userRoles)}
                              >
                                {contourComplexityLevels.map((type) => (
                                  <option key={type} value={type}>{type}</option>
                                ))}
                              </Form.Control>
                            </div>
                          </Form.Group>}
                          {Number(task.project.options.contour_complexity_level) === Number(highestContourComplexityLevel) && (
                            <Form.Group className="row">
                              <Form.Label column className="col-md-4 col-12">{lang['PROJECT.FIELDS.artist_price']}</Form.Label>
                              <div className="col-md-8 col-12">
                                <Form.Control
                                  value={project.options.artist_price}
                                  name='artist_price'
                                  onChange={(event) => this.handleInputChange(event, task)}
                                />
                              </div>
                            </Form.Group>
                          )}
                          <Form.Group className="row">
                            <Form.Label column className="col-md-4 col-12">
                              {lang['PROJECT.FIELDS.color_complexity_level']}
                            </Form.Label>
                            <div className="col-md-8 col-12">
                              <Form.Control
                                as="select"
                                value={project.options.color_complexity_level}
                                name='color_complexity_level'
                                onChange={(event) => this.handleInputChange(event, task)}
                              >
                                {colorComplexityLevels.map((type) => (
                                  <option key={type} value={type}>{type}</option>
                                ))}
                              </Form.Control>
                            </div>
                          </Form.Group>
                          {Number(project.options.color_complexity_level) === Number(highestColorComplexityLevel) && (
                            <Form.Group className="row">
                              <Form.Label column className="col-md-4 col-12">{lang['PROJECT.FIELDS.designer_price']}</Form.Label>
                              <div className="col-md-8 col-12">
                                <Form.Control
                                  value={project.options.designer_price}
                                  name="designer_price"
                                  onChange={(event) => this.handleInputChange(event, task)}
                                />
                              </div>
                            </Form.Group>
                          )}
                        </>}
                        {this.computed.isColoringEditor() && task.type !== 'artist_choosing' && <>
                          <Form.Group className="row">
                            <Form.Label column className="col-md-4 col-12">{`${lang['TASK.BONUS_VALUE']} (€)`}</Form.Label>
                            <div className="col-md-8 col-12">
                              <Form.Control
                                type='number'
                                step='1'
                                // isInvalid={task.bonus && task.bonus.amount && !task.bonus.amount.match(/\d+\.?\d{0,2}/)}
                                min={0}
                                value={task.bonus ? task.bonus.amount : 0}
                                name="amount"
                                onChange={(event) => this.handleInputChange(event, task)}
                              />
                            </div>
                          </Form.Group>
                          <Form.Group className="row">
                            <Form.Label column className="col-md-4 col-12">{lang['TASK.BONUS_REASON']}</Form.Label>
                            <div className="col-md-8 col-12">
                              <Form.Control
                                type='text'
                                className="kt-mb-15"
                                isInvalid={task.bonus && task.bonus.amount && !task.bonus.reason}
                                value={task.bonus ? task.bonus.reason : ''}
                                name="reason"
                                onChange={(event) => this.handleInputChange(event, task)}
                              />
                            </div>
                          </Form.Group>
                        </>}
                        {this.computed.isColoringEditor() && <>
                          <Form.Group className="row">
                            <Form.Label column className="col-md-4 col-12">
                              {lang[task.type === taskTypes.artist_choosing.type ? 'CUSTOMER.TYPE.artist' : 'CUSTOMER.TYPE.designer']}
                              <span style={{ color: 'red' }}>{' *'}</span>
                            </Form.Label>
                            <div className="col-md-8 col-12">
                              <Form.Control
                                size="sm"
                                as={'select'}
                                isInvalid={!task.current_executor_id}
                                value={task.current_executor_id || ''}
                                name="current_executor_id"
                                onChange={(event) => this.handleInputChange(event, task)}
                              >
                                {this._executorsOptionsList(task)}
                              </Form.Control>
                            </div>
                          </Form.Group>
                          <Form.Group className="row">
                            <Form.Label className="col-md-4 col-12">
                              {lang[task.type === taskTypes.artist_choosing.type ? 'TASK.CHOOSE_ARTIST_DEADLINE' : 'TASK.CHOOSE_COLORIST_DEADLINE']}
                              <span style={{ color: 'red' }}>{' *'}</span>
                            </Form.Label>
                            <div className="col-md-8 col-12">
                              <MyDatePicker
                                selected={this.selectedTaskDeadline(task, lang)}
                                minDate={new Date()}
                                maxDate={formatStringToDate(project.deadline_to)}
                                isInvalid={!task.selected_deadline}
                                onChange={(date) => {
                                  this.handleTaskDeadlineChange(date, task);
                                }
                                }
                              />
                            </div>
                          </Form.Group>
                        </>}
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['PROJECT.COMMENT']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <Chat
                              canComment={false}
                              messages={task?.project?.comments?.map((comment) => ({
                                id: comment.id,
                                user_id: comment.user_id,
                                avatar: (comment.user && comment.user.avatar) || '',
                                name: (comment.user ? `${comment.user.username}` : `User #${comment.user_id}`),
                                time: comment.created_at,
                                updated_at: comment.updated_at,
                                deleted_at: comment.deleted_at,
                                isYou: comment.user_id === this.props.user.id,
                                status: comment.status,
                                is_hidden: comment.is_hidden,
                                message: (
                                  <>
                                    {comment.comment}
                                    {comment.files && comment.files.length ? comment.files.map((file) => (
                                      (
                                        <p key={file.id} className="w-100 d-block kt-m-0">
                                          <a
                                            className="font-weight-bold"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={imgURL(file.link)}
                                          >
                                            {lang['PROJECT.ATTACHED_FILE']}
                                          </a>
                                        </p>
                                      )))
                                      : ''}
                                  </>
                                ),
                              }))}
                              onSend={() => {}}
                              onUpdateComment={() => {}}
                              onDeleteComment={() => {}}
                            />
                          </div>
                        </Form.Group>
                        {(this.computed.isColoringEditor() || this.computed.isLeadColoringEditor()) &&
                          <Form.Group className="row">
                            <Form.Label column className="col-md-4 col-12">
                              {lang['TASK.REFERENCE_PROMPT']}
                            </Form.Label>
                            <div className="col-md-8 col-12">
                              <Form.Control
                                as={'textarea'}
                                aria-rowcount={2}
                                value={task.prompt || ''}
                                name="prompt"
                                onChange={(event) => this.handleInputChange(event, task)}
                              />
                            </div>
                          </Form.Group>
                        }
                        {(this.computed.isColoringEditor() || this.computed.isLeadColoringEditor()) &&
                          <Form.Group className="row">
                            <Form.Label column>
                              {`${lang['GLOBAL.REFERENCE_IMAGES']} (${lang['TASK.AVAILABLE_REF_FILES']}: jpg, jpeg, png, bmp, gif, svg, webp.)`}
                            </Form.Label>
                            <Form.Control
                              as={DropFile}
                              className='h-auto dropzone-success kt-m-10'
                              theme='brand'
                              hasCustomText
                              customInnerText={lang['GLOBAL.DROP_REFERENCE_FILES']}
                              showPreview={false}
                              curFiles={selectedRefFiles[index]?.files || []}
                              addFile={(result) => {
                                const totalSelectedRefFiles = [ ...this.state.selectedRefFiles ];

                                totalSelectedRefFiles[index] = {
                                  ...totalSelectedRefFiles[index],
                                  files: [ ...(Array.isArray(selectedRefFiles[index]?.files) ? selectedRefFiles[index].files : []), result ],
                                };
                                this.setState({
                                  selectedRefFiles: [ ...totalSelectedRefFiles ],
                                });
                              }}
                              removeFile={(file) => {
                                const totalSelectedRefFiles = [ ...this.state.selectedRefFiles ];

                                totalSelectedRefFiles[index] = {
                                  files: filter(
                                    selectedRefFiles[index]?.files,
                                    (item) => item !== file
                                  ),
                                };
                                this.setState({
                                  selectedRefFiles: totalSelectedRefFiles,
                                });
                              }}
                            />
                          </Form.Group>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <Modal
            title={lang['TASK.YOU_SURE_ACCEPT_TASKS']}
            show={this.state.showModal}
            close={() => {
              this.setState({
                showModal: false,
              });
            }}
            footer={
              <>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    this.setState({
                      showModal: false,
                    });
                  }}
                >{lang['GLOBAL.CANCEL']}</button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    this._applyTasks();
                  }}
                >{lang['GLOBAL.ACCEPT']}</button>
              </>
            }
          />
        </>);
  }

  async componentDidMount () {
    await this._getTasks();
    if (this.computed.isColoringEditor()) {
      await this._getGroup();
    }
  }

  /**
   * Handle executor change
   *
   * @param {int|string} value
   * @returns {void}
   */
  handleExecutorChange = (value) => {
    this.setState((prevState) => ({
      selectedExecutor: value,
      tasks: prevState.tasks.map((task) => ({
        ...task,
        current_executor_id: value,
      })),
    }), () => {
      this._handleDisabled();
    });
  };

  handleCommentChange = (value, task) => {
    const newTask = {
      ...task,
      project: {
        ...task.project,
        comment: value,
      },
    };

    this.setState((prevState) => ({
      tasks: prevState.tasks.map((stateTask) => {
        if (stateTask.id === task.id) {
          return newTask;
        }

        return stateTask;
      }),
    }));
  };

  handleTaskDeadlineChange = (date, task) => {
    const newTask = {
      ...task,
      selected_deadline: formatDateTimeToString(setDateHours(date)),
    };

    this.setState((prevState) => ({
      tasks: prevState.tasks.map((stateTask) => {
        if (stateTask.id === task.id) {
          return newTask;
        }
        return stateTask;
      }),
    }), () => {
      this._handleDisabled();
    });
  };

  selectedTaskDeadline = (task, lang) => {
    let taskDeadline = getTaskByTypeFromProject(task.project, taskTypes.artist_drawing.type)?.deadline_to;

    if (task.type === taskTypes.designer_choosing.type) {
      taskDeadline = getTaskByTypeFromProject(task.project, taskTypes.designer_coloring.type)?.deadline_to;
    }
    if (task.selected_deadline) {
      return formatStringToDate(task.selected_deadline);
    }
    if (taskDeadline) {
      this.handleTaskDeadlineChange(formatStringToDate(taskDeadline), task);
      return formatStringToDate(taskDeadline);
    }
    return lang[task.type === taskTypes.artist_choosing.type
      ? 'TASK.CHOOSE_ARTIST_DEADLINE_LONG' : 'TASK.CHOOSE_COLORIST_DEADLINE_LONG'];
  };

  handleInputChange = (event, task) => {
    let newTask = {
      ...task,
      project: {
        ...task.project,
        [event.target.name]: event.target.value,
      },
    };

    switch (event.target.name) {
      case 'title': {
        newTask.project = {
          ...task.project,
          [event.target.name]: event.target.value,
        };
        break;
      }
      case 'type':
      case 'difficult':
      case 'designer_price':
        newTask.project = {
          ...task.project,
          options: {
            ...task.project.options,
            [event.target.name]: event.target.value,
          },
        };
        break;
      case 'category_id':
        newTask.project = {
          ...task.project,
          options: {
            ...task.project.options,
            [event.target.name]: mapCategoryNameToId(this.props.categories, event.target.value),
          },
        };
        break;
      case 'current_executor_id':
        newTask = {
          ...task,
          [event.target.name]: event.target.value,
        };
        break;
      case 'amount':
      case 'reason':
        newTask = {
          ...task,
          bonus: {
            ...task.bonus,
            [event.target.name]: event.target.value,
          },
        };
        break;
      case 'contour_complexity_level':
      case 'color_complexity_level':
      case 'artist_price':
      case 'prompt':
        newTask = {
          ...task,
          [event.target.name]: event.target.value,
        };
        break;
      case 'comment_is_hidden':
        newTask = {
          ...task,
          [event.target.name]: event.target.checked,
        };
        break;
      default:
        break;
    }

    const newState = {
      tasks: this.state.tasks.map((stateTask) => {
        if (stateTask.id === task.id) {
          return newTask;
        }

        return stateTask;
      }),
    };

    if (event.target.name === 'title') {
      newState.showInvalid = !!checkStringLength(event.target.value, 151) || containsCyrillic(event.target.value);
    } else if (event.target.name === 'priority') {
      newState.showInvalid = Boolean(!task.project.priority);
    }
    this.setState(newState, () => {
      this._handleDisabled();
    });
  };

  _executorsOptionsList = (task) => {
    let options = [ <option key="0" value="" disabled>{this.props.lang['GLOBAL.CHOOSE_EXECUTOR']}</option> ];

    if (task) {
      options = [ <option key="0" value="" disabled>{this.props.lang[task.type === taskTypes.artist_choosing.type ? 'TASK.CHOOSE_ARTIST' : 'TASK.CHOOSE_DESIGNER']}</option> ];
    }
    if (this.state.executors.length === 0) {
      return options;
    }

    return options.concat(this.state.executors.map((executor) => {
      return <option key={executor.id} value={executor.id}>{executor.username}</option>;
    }));
  };

  _getGroup = async () => {
    this.setState({
      LOAD_GROUP: true,
    });

    try {
      const taskWithChosenGroup = this.state.tasks.find((task) => task.project.options.hasOwnProperty('chosen_group'));

      if (!taskWithChosenGroup) {
        throw new Error('Group not found');
      }

      const msg = await getGroup(taskWithChosenGroup.project.options.chosen_group);

      if (msg) {
        const taskType = taskWithChosenGroup.type === 'artist_choosing' ? typesUsers.artist.key : typesUsers.designer.key;
        const users = msg.users.filter((user) => user.type === taskType).map((user) => user.id);

        const executors = this.props.users.filter((user) => users.includes(user.id));

        this.setState({
          executors,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.props.notification('NOTIFICATION.ERROR_GETTING_GROUP', 'error');
    }

    this.setState({
      LOAD_GROUP: false,
    });
  };

  _getTasks = async () => {
    this._setLoading(true);
    const ids = this.props.location.search?.slice(1)?.split('id[]=')?.filter((id) => id);
    const initialProjectTitles = [];

    await getTasksByIds(ids).then((res) => { // false
      res.map((task) => {
        if (
          !('contour_complexity_level' in task.project.options)
          && task.project.type !== coloringProjectTypes.COLORING_ANY
        ) {
          task.project.options.contour_complexity_level = '2';
        }

        if (!('color_complexity_level' in task.project.options)) {
          task.project.options.color_complexity_level = '1';
        }

        if (!('artist_price' in task.project.options)) {
          task.project.options.artist_price = '25';
        }

        if (!('designer_price' in task.project.options)) {
          task.project.options.designer_price = '13';
        }
        initialProjectTitles.push(task.project.title);
        return task.project.deadline_to;
      });

      this.setState({
        tasks: res,
        initialProjectTitles,
      });
    }).finally(() => {
      this.setState({
        LOAD: false,
      });
    });
  };

  _setUpdatedTasksList = ({ task, preparedTasks }) => {
    const parsedUrl = `${window.location.pathname};`.replace(`${task.id};`, '');

    this.props.history.replace(`${parsedUrl.endsWith(';') ? parsedUrl.slice(0, -1) : parsedUrl}`);

    this.setState({
      itemToDelete: task.id,
      preparedTasks,
      tasks: preparedTasks.filter((item) => item.id !== task.id),
    });
  };

  _getDefaultDeadline = (addDays) => {
    const date = new Date(Math.round((Date.now() + 1000 * 60 * 60) / (60 * 60 * 1000)) * (60 * 60 * 1000));

    return formatDateTimeToString(date.setDate(date.getDate() + addDays));
  };

  _setLoading = (loading) => {
    this.setState({
      LOAD: loading,
    });
  };

  _handleDisabled = () => {

    this.setState({
      isEmptyRequiredField: false,
    });
    this.state.tasks.forEach((stateTask) => {
      if (!stateTask.current_executor_id || !stateTask.selected_deadline) {
        this.setState({
          isEmptyRequiredField: true,
        });
      }
    });
  };

  _projectTitleIsInvalid = (title) => {
    return !title || checkStringLength(title, 151) || containsCyrillic(title) || this.state.invalidProjectTitles.includes(title.trim());
  };

  getTitleUniqueErrorNotification = (invalidProjects) => {
    let message = '';

    invalidProjects.indexes.forEach((index) => {
      message += `${this.props.lang['NOTIFICATION.ERROR_PROJECT_CREATE_UNIQUE_TITLE'].replace('project_number', `№ ${index + 1}`)}\n`;
    });
    return message;
  };

  _applyTasks = async () => {
    this._setLoading(true);

    await this.setState({
      invalidProjectTitles: [],
    });

    let data = {};

    let url = 'tasks/batch-finish';

    try {
      const checkProjectTitleFD = new FormData();

      if (this.computed.isColoringEditor()) {
        const formData = new FormData();

        this.state.tasks.map((task, index) => {
          const updatedTask = {
            id: task.id,
            bonus: task.bonus,
            executor_id: task.current_executor_id || '',
            deadline: task.selected_deadline,
            prompt: typeof task.prompt === 'undefined' ? null : task.prompt,
            comment_is_hidden: typeof task.comment_is_hidden === 'undefined' ? false : task.comment_is_hidden,
            project: {
              comment: task.project.comment || '',
              title: task.project.title || '',
              description: task.project.description || '',
              tags: task.project.tags || [],
              categories: task.project.categories || [],
              image_type: task.project.options.type,
              ...task.project.options,
            },
          };

          if (!updatedTask.project.difficult) {
            // eslint-disable-next-line fp/no-delete
            delete updatedTask.project.difficult;
          }
          if (!this.state.initialProjectTitles.includes(updatedTask.project.title.trim())) {
            checkProjectTitleFD.append(`projects[${index}][title]`, updatedTask.project.title.trim());
          }

          formData.append(`tasks[${index}][id]`, updatedTask.id);
          formData.append(`tasks[${index}][executor_id]`, updatedTask.executor_id);
          formData.append(`tasks[${index}][deadline]`, updatedTask.deadline);
          formData.append(`tasks[${index}][prompt]`, updatedTask.prompt);

          formData.append(`tasks[${index}][project][comment]`, updatedTask.project.comment);
          formData.append(`tasks[${index}][project][comment_is_hidden]`, updatedTask.comment_is_hidden);
          formData.append(`tasks[${index}][project][title]`, updatedTask.project.title);
          formData.append(`tasks[${index}][project][description]`, updatedTask.project.description);
          formData.append(`tasks[${index}][project][image_type]`, updatedTask.project.image_type);

          if (updatedTask.bonus) {
            Object.entries(updatedTask.bonus).forEach(([ key, value ]) => {
              formData.append(`tasks[${index}][bonus][${key}]`, value);
            });
          }

          updatedTask.project.tags.forEach((tag, tagIndex) => {
            Object.entries(tag).forEach(([ key, value ]) => {
              formData.append(`tasks[${index}][project][tags][${tagIndex}][${key}]`, value);
            });
          });

          updatedTask.project.categories.forEach((category, categoryIndex) => {
            Object.entries(category).forEach(([ key, value ]) => {
              formData.append(`tasks[${index}][project][categories][${categoryIndex}][${key}]`, value);
            });
          });

          Object.entries(task.project.options).forEach(([ key, value ]) => {
            if (key === 'difficult' && !updatedTask.project.difficult) {
              return;
            }
            formData.append(`tasks[${index}][project][${key}]`, value);
          });

          this.state.selectedRefFiles[index]?.files.forEach((file, fileIndex) => {
            formData.append(`tasks[${index}][references][${fileIndex}]`, file.file, file.name);
          });

          return updatedTask;
        });

        formData.append('current_role', this.props.currentRole);
        data = formData;
      } else {
        data = {
          tasks: this.state.tasks.map((task, index) => {
            const updatedTask = {
              id: task.id,
              project: {
                ...task.project,
                comment_is_hidden: typeof task.comment_is_hidden === 'undefined' ? false : task.comment_is_hidden,
                tags: [],
                categories: [],
                deadline: task.project.deadline_to,
                image_type: task.project.options.type,
                category_id: task.project.options.category_id,
                hc_release_date: task.project.hc_release_date,
                hc_content_type: task.project.hc_content_type,
              },
            };

            if (task.project.options.difficult) {
              updatedTask.project.difficult = task.project.options.difficult;
            }

            if (!updatedTask.project.difficult) {
              // eslint-disable-next-line fp/no-delete
              delete updatedTask.project.difficult;
            }

            task.project.tags.forEach((tag) => {
              updatedTask.project.tags.push(tag.id);
            });

            task.project.categories.forEach((category) => {
              updatedTask.project.categories.push(category.id);
            });

            updatedTask.project.tasks = {};
            task.project.tasks.forEach((task) => {
              updatedTask.project.tasks[task.type] = {};
              updatedTask.project.tasks[task.type].executor = task.executor_id || '';
            });

            if (!this.state.initialProjectTitles.includes(updatedTask.project.title.trim())) {
              checkProjectTitleFD.append(`projects[${index}][title]`, updatedTask.project.title.trim());
            }
            return updatedTask;
          }),
        };
        url = 'tasks/batch-accept';
      }
      if ([ ...checkProjectTitleFD ].length > 0) {
        const checkProjectResponse = await axiosApiInstance.post('projects/check', checkProjectTitleFD, {});
        const invalidProjects = checkProjectResponse.data?.data?.invalid;

        if (invalidProjects.titles.length > 0) {
          this.setState({
            invalidProjectTitles: invalidProjects.titles,
            showModal: false,
            LOAD: false,
          });
          this.props.notification('', 'error', this.getTitleUniqueErrorNotification(invalidProjects));
        }
      }

      if (this.state.invalidProjectTitles.length > 0) {
        this.setState({
          showModal: false,
          LOAD: false,
        });
        const element = document.querySelector('.title-unique-validation');

        element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        return;
      }
      const res = await axiosApiInstance.post(url, data);

      if (res.data && res.data.message) {
        this.props.notification('NOTIFICATION.ERROR_ACCEPT_TASKS', 'error', res.data.message);
      } else {
        this.props.notification('NOTIFICATION.SUCCESS_ACCEPT_TASKS', 'success');
        this.props.history.goBack();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.props.notification('NOTIFICATION.ERROR_ACCEPT_TASKS', 'error');
      this._setLoading(false);
    }
  };
}

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    user: store.user.user,
    userRoles: store.user.roles,
    currentRole: store.user.currentRole,
    categories: store.categories,
    users: store.users,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

TasksAccept.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(TasksAccept);
