import React from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { tagValidator } from '../utils/validators';
import toSlug from '../pages/tags/toSlug';
import { prepareTags, addTag } from '../requests/tags';

import MySelect from './MySelect';



const propTypes = {
  canCreate: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  filter: PropTypes.func,
  lang: PropTypes.object,
  addTag: PropTypes.func,
  tags: PropTypes.any,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};

/**
 * Component for select tags
 *
 * @returns {*}
 */
class SelectHashtag extends React.PureComponent {
  state = {
    fullList: [],
    list: [],
    value: '',
    isLoading: false,
  };

  render () {
    return (
      <MySelect
        as={this.props.canCreate && CreatableSelect}
        isClearable={this.props.isClearable}
        isMulti
        isLoading={this.state.isLoading}
        isDisabled={this.state.isLoading || this.props.disabled}
        inputValue={this.state.value}
        onInputChange={(value) => {
          this.setState({
            value: tagValidator(value),
          });
        }}
        closeMenuOnScroll
        options={this.state.list}
        classNamePrefix={'tags'}
        value={this.state.list.filter((tag) => this.props.value.findIndex((item) => item === tag.value) !== -1)}
        onChange={(value) => {
          this.props.onChange(value ? value.map((v) => v.value) : [], value || []);
        }}
        onCreateOption={this.createTag}
        components={{
          MenuList: (props) => {
            return (
              <components.MenuList {...props}>
                <div className='d-flex flex-wrap justify-content-between'>
                  {props.children}
                </div>
              </components.MenuList>
            );
          },
          Option: (props) => {
            return (
              <div
                style={{
                  backgroundColor: 'hsl(0,0%,90%)',
                  borderRadius: 2,
                  display: 'flex',
                  margin: 2,
                  minWidth: 0,
                  boxSizing: 'border-box',
                  flex: '1 0 auto',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <components.Option {...props}>
                  {props.data.__isNew__
                    ? <b>
                      {`${this.props.lang['GLOBAL.CREATE']} "${props.value}"`}
                    </b>
                    : props.children
                  }
                </components.Option>
              </div>);
          },
        }}
      />
    );
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        list: this.props.filter ? this.state.fullList.filter(this.props.filter) : this.state.fullList,
      });
    }

    if (prevProps.tags.length !== this.props.tags.length) {
      this.getTags();
    }
  }

  componentDidMount () {
    this.getTags();
  }

  createTag = () => {
    const { value } = this.state;
    const slug = toSlug(value);

    const tag = this.state.list.find((item) => item.slug === slug);

    if (tag && !this.props.value.includes(tag.value)) {
      const newValue = [ ...this.props.value, tag.value ];

      this.props.onChange(newValue, this.state.list.filter((tag) => newValue.findIndex((item) => item === tag.value) !== -1));
    } else if (!tag) {
      this.postTag(value, slug).then();
    }

    this.setState({
      value: '',
    });
  };

  getTags = () => {
    const tags = prepareTags(this.props.tags);

    this.setState({
      fullList: tags,
      list: this.props.filter ? tags.filter(this.props.filter) : tags,
    });
  };

  /**
   * fetch for create new tag
   *
   * @param {string} tag
   * @param {string} slug
   * @returns {Promise<void>}
   * @private
   */
  postTag = async (tag, slug) => {
    this.setState({
      isLoading: true,
    });
    const fd = new FormData();
    const value = this.props.value;

    fd.append('name', tag);
    fd.append('slug', slug);

    await this.props.addTag(fd, value, tag, slug).then(async (newValue) => {
      await this.getTags();
      this.props.onChange(newValue, this.state.fullList.filter((tag) => newValue.findIndex((item) => item === tag.value) !== -1));
    });

    this.setState({
      isLoading: false,
    });
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    tags: store.tags,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTag: bindActionCreators(addTag, dispatch),
  };
};

SelectHashtag.defaultProps = {
  canCreate: true,
  isClearable: true,
  disabled: false,
  value: [],
};

SelectHashtag.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(SelectHashtag);
