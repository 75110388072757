import { formatDateToString } from '../app/utils/formats';



export const today = new Date();
export const curMonth = new Date(today.getFullYear(), today.getMonth());
export const historyFrom = new Date(curMonth.getFullYear(), curMonth.getMonth() - 3);
export const historyTo = new Date(curMonth.getFullYear(), curMonth.getMonth());
export const prevMonthFirstDate = formatDateToString(new Date(today.getFullYear() - (today.getMonth() > 0 ? 0 : 1), (today.getMonth() - 1 + 12) % 12, 1));
export const prevMonthLastDate = formatDateToString(new Date(today.getFullYear(), today.getMonth(), 0));

export const lastAllowedDateToAcceptTesterBonus = 5;
