import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import MyDatePicker from '../../widgets/DatePicker';
import { parseFromPars } from '../../utils/parseUrlParams';
import SelectHashtag from '../../widgets/SelectHashtag';
import { mapCategoryNameToId, mapCategoryIdToName } from '../../requests/categories';
import axiosApiInstance from '../../requests/utils/api';
import taskTypes from '../tasks/taskTypes';
import { notification } from '../../requests/notifications';
import MySelect from '../../widgets/MySelect';
import Preload from '../../widgets/Preload';
import { formatDateTimeToString, formatStringToDate, setDateHours } from '../../utils/formats';
import roles from '../customers/roles/roles';
import checkRole from '../../utils/checkRole';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import urlPageProjectDetails from '../../urls/urlPageProjectDetails';
import urlPageTaskDetails from '../../urls/urlPageTaskDetails';
import { checkStringLength } from '../../utils/checker';
import DropFile from '../../widgets/DropFile';
import { toImgUrl } from '../../utils/gettersUrl';
import calculateTaskPriceByUserRate from '../tasks/helpers/calculateTaskPriceByUserRate';
import { getUser } from '../../requests/users';
import SelectCategories from '../../widgets/SelectCategories';
import groupsRoles from '../customers/roles/groupsRoles';
import { getProjectDeadlineByHCReleaseDate } from '../../utils/getProjectDeadlineByHCReleaseDate';
import { containsCyrillic } from '../../utils/validators';
import { globalTypesProject, projectTypeByKey } from './projectTypes';
import priorities, { prioritiesMap } from './priorities';
import isCategoryInProjectCategories from './helpers/isCategoryInProjectCategories';
import difficultyTypes from './difficultyTypes';
import hcContentTypes from './hcContentTypes';



const propTypes = {
  lang: PropTypes.object.isRequired,
  cRoles: PropTypes.array,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object,
  notification: PropTypes.func,
  history: PropTypes.object,
};

class ProjectEdit extends React.Component {
  state = {
    project: {},
    LOAD_PROJECT: true,
    showInvalid: false,
    isUniqueProjectTitle: true,
    initialProjectTitle: '',
  };

  render () {
    const { lang, cRoles } = this.props;
    const { project, showInvalid } = this.state;
    const showChooseExecutorBlock = checkRole(cRoles, [ roles['lead-coloring-editor'].key, roles.administrator.key ]);
    const canEdit = !checkRole([ ...groupsRoles.editors.all ], cRoles);
    const params = parseFromPars(this.props.location.search);
    const canEditOnlyTitleDesc = !checkRole(cRoles, [ roles['lead-coloring-editor'].key, roles['content-manager'].key, roles.administrator.key ]);
    const isEditor = checkRole(cRoles, [ roles['lead-coloring-editor'].key, roles.administrator.key, roles['coloring-editor'].key, roles['remote-coloring-editor'].key ]);

    return this.state.LOAD_PROJECT ? (
      <Preload />
    ) : (<>
      <SubHeader
        title={lang['PROJECT.EDIT']}
        toolbar={
          <>
            <Button
              as={Link}
              to={params.task ?
                urlPageTaskDetails({ taskId: params.task }) :
                urlPageProjectDetails({ projectId: project.id })
              }
              variant={'secondary'}
              className='font-weight-bold'
            >
              {lang['GLOBAL.CANCEL']}
            </Button>
            <Button
              variant={'success'}
              className='font-weight-bold'
              disabled={!this._canSend()}
              onClick={() => this._canSend() && this._checkProject()}
            >
              {lang['GLOBAL.SAVE']}
            </Button>
          </>
        }
      />
      <div className='kt-portlet'>
        <form
          className='kt-portlet__body'
          onSubmit={(event) => {
            event.preventDefault();
            this._putProject();
          }}
        >
          <input style={{ display: 'none' }} type='submit' />
          <div className="row">
            <div className="offset-xl-2 col-xl-8 col-12">
              <div className="kt-section kt-section--first">
                <div className="kt-section__body">
                  <h3 className="kt-section__title kt-section__title-lg">{lang['PROJECT.BASIC_DATA']}:</h3>
                  <Form.Group className="row" title={lang['PROJECT.TITLE_LANG_ALERT']}>
                    <Form.Label column className="col-md-4 col-12">
                      {lang['PROJECT.TITLE']}
                      <span style={{ color: 'red' }}>
                        {' *'}
                      </span></Form.Label>
                    <div className="col-md-8 col-12">
                      <Form.Control
                        type="text"
                        isInvalid={!this.isValidProjectTitle(project.title)}
                        value={project.title || ''}
                        onChange={(event) => {
                          this.setState({
                            project: {
                              ...project,
                              title: event.target.value,
                            },
                          });
                        }}
                      />
                      {!this.state.isUniqueProjectTitle ? (
                        <Form.Control.Feedback type="invalid" className="title-unique-validation">
                          {lang['PROJECT.TITLE_UNIQUE_VALIDATION_ERROR']}
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          {lang['PROJECT.TITLE_VALIDATION_ERROR']}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <Form.Label column className="col-md-4 col-12">
                      {lang['PROJECT.DESCRIPTION']}
                    </Form.Label>
                    <div className="col-md-8 col-12">
                      <Form.Control
                        as='textarea'
                        value={project.description || ''}
                        onChange={(event) => {
                          this.setState({
                            project: {
                              ...project,
                              description: event.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </Form.Group>
                  {isEditor && (
                    <Form.Group className="row">
                      <Form.Label column className="col-md-4 col-12">
                        {lang['TASK.REFERENCE_PROMPT']}
                      </Form.Label>
                      <div className="col-md-8 col-12">
                        <Form.Control
                          as='textarea'
                          value={project.prompt || ''}
                          onChange={(event) => {
                            this.setState({
                              project: {
                                ...project,
                                prompt: event.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </Form.Group>
                  )}
                  {!canEditOnlyTitleDesc &&
                    <>
                      <Form.Group className="row">
                        <Form.Label column className="col-md-4 col-12">
                          {lang['GLOBAL.CATEGORY']}
                        </Form.Label>
                        <div className="col-md-8 col-12">
                          <Form.Control
                            as="select"
                            disabled={checkRole(cRoles, [ roles['lead-coloring-editor'].key ])}
                            isInvalid={showInvalid && canEdit}
                            value={mapCategoryIdToName(this.props.categories, project.options.category_id)}
                            onChange={(event) => {
                              const options = project.options;

                              this.setState({
                                project: {
                                  ...project,
                                  options: {
                                    ...options,
                                    category_id: mapCategoryNameToId(this.props.categories, event.target.value, false),
                                  },
                                },
                              });
                            }}
                          >
                            {this.props.categories.map((item) => {
                              if (isCategoryInProjectCategories(project, item)) {
                                return;
                              }
                              return (
                                <option key={item.id} value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </div>
                      </Form.Group>
                      {checkRole(cRoles, [ roles['content-manager'].key ]) && (
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['MENU.CATEGORIES']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <SelectCategories
                              filter={(category) => this.props.categories.findIndex(
                                () => (category.value === project.options?.category_id)
                              ) === -1}
                              value={project.categories.map((category) => category.id)}
                              onChange={(ids, categories) => {
                                const newProject = {
                                  ...project,
                                  categories: categories.map((category) => ({
                                    id: category.value,
                                    name: category.label,
                                    slug: category.slug,
                                  })),
                                };

                                this.setState({
                                  project: newProject,
                                });
                              }}
                            />
                          </div>
                        </Form.Group>
                      )}
                      {projectTypeByKey[project.type] && projectTypeByKey[project.type].type === globalTypesProject.COLORING &&
                        !checkRole(cRoles, [ roles['lead-coloring-editor'].key ]) && (
                        <Form.Group className="row">
                          <Form.Label column className="col-md-4 col-12">
                            {lang['MENU.TAGS']}
                          </Form.Label>
                          <div className="col-md-8 col-12">
                            <SelectHashtag
                              value={project.tags.map((tag) => tag.id)}
                              onChange={(ids, tags) => {
                                const newProject = {
                                  ...project,
                                  tags: tags.map((tag) => ({
                                    id: tag.value,
                                    name: tag.label,
                                    slug: tag.slug,
                                  })),
                                };

                                this.setState({
                                  project: newProject,
                                });
                              }}
                            />
                          </div>
                        </Form.Group>
                      )}
                      <Form.Group className="row">
                        <Form.Label column className="col-md-4 col-12">
                          {lang['PROJECT.FIELDS.hc_release_date']}
                        </Form.Label>
                        <div className="col-md-8 col-12">
                          <MyDatePicker
                            placeholder={lang['GLOBAL.NO_CHOOSE']}
                            disabled={!canEdit}
                            selected={project.hc_release_date ? formatStringToDate(project.hc_release_date) : ''}
                            minDate={new Date()}
                            onChange={(date) => {
                              if (date) {
                                date = formatDateTimeToString(setDateHours(date));
                                this.setState({
                                  project: {
                                    ...project,
                                    hc_release_date: date,
                                    deadline_to: getProjectDeadlineByHCReleaseDate(date),
                                  },
                                });
                              } else {
                                this.setState({
                                  project: {
                                    ...project,
                                    hc_release_date: '',
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <Form.Label column className="col-md-4 col-12">
                          {lang['GLOBAL.DEADLINE']}
                          <span style={{ color: 'red' }}>{' *'}</span>
                        </Form.Label>
                        <div className="col-md-8 col-12">
                          <MyDatePicker
                            isInvalid={showInvalid && canEdit ? !(project.deadline_to || '').trim() : false}
                            disabled={checkRole(cRoles, [ roles['lead-coloring-editor'].key ])}
                            selected={project.deadline_to ? formatStringToDate(project.deadline_to) : ''}
                            minDate={new Date()}
                            onChange={(date) => {
                              if (date) {
                                date = formatDateTimeToString(setDateHours(date));
                                this.setState({
                                  project: {
                                    ...project,
                                    deadline_to: date,
                                  },
                                });
                              } else {
                                this.setState({
                                  project: {
                                    ...project,
                                    deadline_to: '',
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <Form.Label column className="col-md-4 col-12">
                          {lang['PROJECT.FIELDS.hc_content_type']}
                        </Form.Label>
                        <div className="col-md-8 col-12">
                          <Form.Control
                            as="select"
                            disabled={!canEdit}
                            value={project.hc_content_type || ''}
                            onChange={(event) => {
                              this.setState({
                                project: {
                                  ...project,
                                  hc_content_type: event.target.value,
                                },
                              });
                            }}
                          >
                            <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
                            {hcContentTypes.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {lang[`PROJECT.FIELDS.hc_content_type.${item}`]}
                                </option>);
                            })}
                          </Form.Control>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <Form.Label column className="col-md-4 col-12">
                          {lang['GLOBAL.PRIORITY']}
                          <span style={{ color: 'red' }}>{' *'}</span>
                        </Form.Label>
                        <div className="col-md-8 col-12">
                          <Form.Control
                            as="select"
                            disabled={checkRole(cRoles, [ roles['lead-coloring-editor'].key ])}
                            isInvalid={showInvalid && canEdit ? !(project.priority || '').trim() : false}
                            value={project.priority}
                            onChange={(event) => {
                              this.setState({
                                project: {
                                  ...project,
                                  priority: event.target.value,
                                },
                              });
                            }}
                          >
                            {priorities.map((item) => {
                              return (
                                <option key={item} value={prioritiesMap[item]}>
                                  {lang[`GLOBAL.PRIORITY.${prioritiesMap[item]}`]}
                                </option>);
                            })}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {lang['GLOBAL.FIELD_REQUIRED']}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <Form.Label column className="col-md-4 col-12">
                          {lang['PROJECT.FIELDS.taps']}
                        </Form.Label>
                        <div className="col-md-8 col-12">
                          <Form.Control
                            type="text"
                            value={project.options.taps || '---'}
                            disabled
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <Form.Label column className="col-md-4 col-12">
                          {lang['PROJECT.FIELDS.difficult']}
                        </Form.Label>
                        <div className="col-md-8 col-12">
                          <Form.Control
                            as="select"
                            disabled={!canEdit}
                            value={project.options.difficult || ''}
                            onChange={(event) => {
                              const options = project.options;

                              this.setState({
                                project: {
                                  ...project,
                                  options: {
                                    ...options,
                                    difficult: event.target.value,
                                  },
                                },
                              });
                            }}
                          >
                            <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
                            {difficultyTypes.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {lang[`PROJECT.FIELDS.difficult.${item}`]}
                                </option>);
                            })}
                          </Form.Control>
                        </div>
                      </Form.Group>
                      <Form.Group className="row">
                        <Form.Label
                          column
                          className="col-md-4 col-12"
                        >
                          {lang['PROJECT.SOURCE_FILES']}
                        </Form.Label>
                        <div className="col-12" style={{ paddingLeft: '10px' }}>
                          <DropFile
                            curFiles={project.sourceFile}
                            addFile={(file) => {
                              this.setState({
                                project: {
                                  ...project,
                                  sourceFile: [ ...(project.sourceFile || []), file ],
                                },
                              });
                            }}
                            removeFile={(file) => {
                              this.setState({
                                project: {
                                  ...project,
                                  sourceFile: project.sourceFile.filter((item) => item.file.name !== file.file.name),
                                },
                              });
                            }}
                          />
                        </div>
                      </Form.Group>
                    </>
                  }
                </div>
              </div>
              <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
              {showChooseExecutorBlock && <div className="kt-section">
                <div className="kt-section__body">
                  <h3 className="kt-section__title kt-section__title-lg">{lang['PROJECT.STEP_SETTINGS']}:</h3>
                  <div className="row">
                    {project.tasks && project.tasks.sort((task1, task2) => task1.id - task2.id)
                      .map((task, index) => {
                        const user = this.props.users.find((user) => user.id === task.executor_id);

                        const value = user ? {
                          value: user.id,
                          label: user.username,
                        } : null;

                        return (
                          <div className="col-6" key={task.id}>
                            <h5>{lang[`TASK.TYPES.${task.type}`]}</h5>
                            <Form.Group>
                              <Form.Label>{lang['GLOBAL.EXECUTOR']}</Form.Label>
                              <MySelect
                                className="kt-mb-15"
                                isClearable
                                value={value}
                                options={this.props.users.filter((user) => checkRole(user.roles, [ taskTypes[task.type].role ])).map((user) => ({
                                  value: user.id,
                                  label: user.username,
                                  isDisabled: user.inactive,
                                }))}
                                onChange={(value) => {
                                  const tasks = [ ...project.tasks ];

                                  tasks[index] = {
                                    ...task,
                                    executor_id: value ? value.value : '',
                                  };

                                  this.setState({
                                    project: {
                                      ...project,
                                      tasks,
                                    },
                                  });
                                }}
                              />
                            </Form.Group>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </form>
      </div>
    </>
    );
  }

  componentDidMount () {
    (async () => {
      await this._getProject();
    })();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.user.id !== this.props.user.id) {
      this.setState({
        project: {
          ...this.state.project,
          sourceFile: this.state.project.files?.filter((file) => {
            return file.status === 1 && file.prefix === 'source' && file.user_id === nextProps.user.id;
          }).map((file) => {
            return {
              id: file.id,
              file: new File([ '' ], file.link), // ugly hack to show existed files
              preview: toImgUrl(file.link),
            };
          }),
        },
      });
    }
  }

  _getProject = () => {
    this.setState({
      LOAD_PROJECT: true,
    });

    return axiosApiInstance.get(`projects/${this.props.match.params.id}`, {})
      .then((response) => {
        const responseProject = {
          ...response.data.project,
          sourceFile: response.data.project.files.filter((file) => {
            return file.status === 1 && file.prefix === 'source' && file.user_id === this.props.user.id;
          }).map((file) => {
            return {
              id: file.id,
              file: new File([ '' ], file.link), // ugly hack to show existed files
              preview: toImgUrl(file.link),
            };
          }),
        };

        this.setState({
          project: responseProject,
          LOAD_PROJECT: false,
          initialProjectTitle: responseProject.title,
        });
      })
      .catch((error) => {
        if (error.response) {
          // eslint-disable-next-line no-console
          console.error(error.response.data);
          this.setState({
            LOAD_PROJECT: false,
          });
        }
      });
  };

  /**
   * Get default deadline
   *
   * @param {*} addDays
   * @returns {string}
   * @private
   */
  _getDefaultDeadline = (addDays) => {
    return formatDateTimeToString(new Date().setDate(new Date().getDate() + addDays));
  };

  isValidProjectTitle = (title) => {
    return (!checkStringLength(title, 151) && this.state.isUniqueProjectTitle && !containsCyrillic(title));
  };

  _checkProject = () => {
    const { project, initialProjectTitle } = this.state;

    if (initialProjectTitle !== project.title) {
      const fd = new FormData();

      fd.append('projects[0][title]', project.title.trim());

      this.setState({
        LOAD_PROJECT: true,
      });
      axiosApiInstance.post('projects/check', fd, {})
        .then((response) => {
          if (response.data.data?.invalid?.titles?.length > 0) {
            this.setState({
              isUniqueProjectTitle: false,
              LOAD_PROJECT: false,
            });
            this.props.notification('PROJECT.TITLE_UNIQUE_VALIDATION_ERROR', 'error');
          } else {
            this.setState({
              isUniqueProjectTitle: true,
            });
            this._putProject();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.props.notification('NOTIFICATION.ERROR_PROJECT_EDIT', 'error');

            this.setState({
              LOAD_PROJECT: false,
            });
          }
        });
    } else {
      this._putProject();
    }
  };

  _putProject = () => {
    this.setState({
      LOAD_PROJECT: true,
    });
    const { project } = this.state;
    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append('type', 'form');
    fd.append('title', project.title);
    if (!isEmpty(project.options.difficult)) {
      fd.append('difficult', project.options.difficult);
    }
    if (project.description) {
      fd.append('description', project.description);
    }
    fd.append('prompt', project.prompt ? project.prompt : '');
    fd.append('deadline', project.deadline_to);
    fd.append('hc_release_date', project.hc_release_date);
    fd.append('hc_content_type', project.hc_content_type);
    fd.append('priority', project.priority);
    project.tags.forEach((tag, index) => {
      fd.append(`tags[${index}]`, tag.id);
    });
    fd.append('category_id', project.options.category_id);
    project.tasks.forEach((task) => {

      fd.append(`executors[${task.type}]`, task.executor_id || '');
      if (!checkRole(this.props.cRoles, [ roles['content-manager'].key ])) {
        const executor = getUser(task.executor_id);

        fd.append(`prices[${task.type}]`, calculateTaskPriceByUserRate(task, executor, project.options));
      }
    });

    if (project.sourceFile) {
      project.sourceFile.forEach((file, i) => {
        fd.append(`files[${i}]`, file.file, file.file.name || file.preview);
      });
    }
    project.categories.forEach((category, index) => {
      fd.append(`categories[${index}]`, category.id);
    });

    axiosApiInstance.post(`projects/${project.id}`, fd)
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_EDIT', 'success');

        const params = parseFromPars(this.props.location.search);

        if (params.task) {
          this.props.history.push(urlPageTaskDetails({ taskId: params.task }));
        } else {
          this.props.history.push(urlPageProjectDetails({ projectId: project.id }));
        }
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_PROJECT_EDIT', 'error');

          this.setState({
            LOAD_PROJECT: false,
          });
        }
      });
  };

  _canSend = () => {
    const { project } = this.state;

    if (checkStringLength(project.title, 151) || containsCyrillic(project.title)) {
      return false;
    }

    if (checkRole(this.props.cRoles, [ roles['lead-coloring-editor'].key ])) {
      return true;
    }
    return (
      (project.title || '').trim()
         && (project.deadline_to || '').trim()
         && (project.priority || '')
    );
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    cRoles: store.user.roles,
    categories: store.categories,
    users: store.users,
    user: store.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

ProjectEdit.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(ProjectEdit);
