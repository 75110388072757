import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { filter } from 'lodash';
import React from 'react';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import { status } from '../../../utils/statusToColor';
import DropFile from '../../../widgets/DropFile';
import { addTagsToProject } from '../../../requests/tags';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';
import taskTypes from '../taskTypes';
import MyComponent from '../../../utils/MyComponent';
import SelectHashtag from '../../../widgets/SelectHashtag';
import axiosApiInstance from '../../../requests/utils/api';
import { notification } from '../../../requests/notifications';
import { checkStringLength } from '../../../utils/checker';
import { SPECIAL_TAGS } from '../../../../const/project';
import UploadReference from '../components/UploadReference';

/**
 * Component of the task completion block of the task page
 *
 * @returns {*}
 */
class TaskInfoFinishBlock extends MyComponent {
  state = {
    showLoadFiles: false,
    selectGroup: '',
    selectedFiles: [],
    title: null,
    message: null,
    selectTags: [],
  };

  computed = {
    canEditTitle: (state, { task }) => {
      return !!(task.type ? checkRole([ roles['coloring-artist'].key ], [ taskTypes[task.type].role ]) : null);
    },
    files: (state, { task, config }) => {
      let files = [];

      if (task.project && config && config[task.project.type] && config[task.project.type].files) {
        const _files = task.project.options.type ? config[task.project.type].files[task.project.options.type] : config[task.project.type].files;

        files = _files.filter((file) => file.step === task.type);
      }

      return files;
    },
    filesIsValid: (state, { taskFiles }) => {
      return taskFiles.length > 0 && taskFiles.reduce((status, file) => {
        return file.status;
      }, true);
    },

    minCountTags: (state, { task, userId }) => {
      let count = task.type && (task.type === taskTypes.artist_drawing.type || task.type === taskTypes.designer_coloring)
        ? (taskTypes[task.type].role === roles['coloring-artist'].key
          ? (task.is_reopened ? 0 : 1) : 2) : 0;

      if (count > 0) {
        const countTagsUser = task.project && task.project.tags ? task.project.tags.filter((tag) => tag.user_id === userId).length : 0;

        count -= countTagsUser;

        if (count < 0) {
          count = 0;
        }
      }
      return count;
    },
    canTurn: ({ title, message, selectTags }, { task }) => {
      title = title === null ? (task.project && task.project.title) || '' : title;
      message = message === null ? (task.project && task.project.description) || '' : message;

      return !(((task.project.tags.length < 1) && (selectTags.length < this.computed.minCountTags)) || !this.computed.filesIsValid || (this.computed.canEditTitle ?
        (!message || !title || message.trim().length < 4 || title.trim().length <= 0) : false)
      );
    },
  };

  render () {
    const {
      title,
      message,
      selectedFiles,
      selectTags,
    } = this.state;

    const {
      task,
      groups,
      executors,
      lang,
      userId,
      canSetTags,
      isChoseGroup,
      isChoseExecutor,
      isWork,
      sizingBlocks,
      currentRole,
    } = this.props;

    const {
      canEditTitle,
      files,
      filesIsValid,
      minCountTags,
      canTurn,
    } = this.computed;

    return (task.status === status.process) && task.executor_id === userId && (
      <>
        {!isChoseGroup && !isChoseExecutor && isWork && (
          <div className={`col-xl-${sizingBlocks.xl} col-lg-${sizingBlocks.lg} col-md-${sizingBlocks.md} col-sm-${sizingBlocks.sm} col-12`}>
            <div className="kt-portlet kt-bg-success kt-portlet--skin-solid kt-portlet--height-fluid">
              <div className="kt-portlet__head kt-portlet__head--noborder">
                <div className="kt-portlet__head-label d-flex w-100 justify-content-between">
                  <h3 className="kt-portlet__head-title font-weight-bold">
                    {lang['TASK.TURN']}
                  </h3>
                  {files?.length > 0 && filesIsValid ? (
                    <Button
                      variant='light'
                      className='kt-ml-5'
                      size='sm'
                      onClick={() => {
                        this.setState({
                          showLoadFiles: !this.state.showLoadFiles,
                        });
                      }}
                    >
                      {this.state.showLoadFiles ? lang['TASK.TURN'] : lang['TASK.TO_LOAD_FILES']}
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="kt-portlet__body kt-portlet__body--fullheight kt-pt-5">
                {files.length > 0 && (!filesIsValid || this.state.showLoadFiles) ? (<>
                  <Form.Group className='kt-mb-10'>
                    <Form.Label className='w-100'>
                      {lang['TASK.NEED_LOAD_FILES']}
                    </Form.Label>
                    {(() => {
                      const required = files ? files.filter((file) => file.required) : [];
                      const optionals = files ? files.filter((file) => !file.required) : [];

                      return (
                        <>
                          {required.length > 0 && (
                            <Form.Label className='w-100'>
                              {`${lang['TASK.REQUEST_FILES']}: ${required.map((file) => file.name).join(', ')}.`}
                            </Form.Label>
                          )}
                          {optionals.length > 0 && (
                            <Form.Label className='w-100'>
                              {`${lang['TASK.OPTIONALS_FILES']}: ${optionals.map((file) => file.name).join(', ')}.`}
                            </Form.Label>
                          )}
                        </>
                      );
                    })()}
                  </Form.Group>
                  <Form.Group className='kt-mb-20'>
                    <Form.Control
                      as={DropFile}
                      className='h-auto'
                      theme='brand'
                      showPreview={false}
                      styles={{ minHeight: 110 }}
                      curFiles={selectedFiles}
                      addFiles={(result) => {
                        this.setState({
                          selectedFiles: [ ...selectedFiles, ...result ],
                        });
                      }}
                      removeFile={(file) => {
                        this.setState({
                          selectedFiles: filter(selectedFiles, (item) => item !== file),
                        });
                      }}
                    />
                  </Form.Group>
                  <div className='d-flex justify-content-center kt-mt-a kt-mb-20'>
                    <Button
                      variant='light'
                      className='btn-upper mx-1 font-weight-bold'
                      disabled={selectedFiles.length === 0}
                      type='submit'
                      onClick={() => {
                        const _files = this._checkFiles(files, selectedFiles);

                        if (_files.length) {
                          this._loadMainFiles(_files, () => {
                            this.setState({
                              selectedFiles: [],
                              showLoadFiles: false,
                            });
                          });
                        } else {
                          this.props.notification('TASK.ERROR_FILES', 'error');
                        }
                      }}
                    >
                      {lang['GLOBAL.UPLOAD']}
                    </Button>
                  </div>
                  {(currentRole === roles['coloring-artist'].key || currentRole === roles['coloring-designer'].key) && (
                    <UploadReference
                      executors={executors}
                      task={task}
                      finishTask={this.props.finishTask}
                      getTask={this.props.getTask}
                      showUploadButton
                    />
                  )}
                </>
                ) : (
                  <>
                    {(canEditTitle || (canSetTags && minCountTags > 0)) && (
                      <Form.Group>
                        <Badge variant='warning' className="text-wrap">
                          {lang['TASK.FOR_TURN']}
                        </Badge>
                      </Form.Group>
                    )}
                    {canEditTitle && (
                      <Form.Group title={lang['PROJECT.TITLE_LANG_ALERT']}>
                        <Form.Label>
                          {lang['TASK.TITLE']}
                          <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                          value={title === null ? (task.project && task.project.title) || '' : title}
                          isInvalid={title ? checkStringLength(title, 151) : null}
                          onChange={(event) => {
                            this.setState({
                              title: event.target.value?.replace(/[А-Яа-я]/, ''),
                            });
                          }}
                        />
                        <Form.Text>{lang['GLOBAL.PLEASE_ENTER_TITLE']}</Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {lang['PROJECT.TITLE_VALIDATION_ERROR']}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    {canSetTags && (
                      <Form.Group>
                        <Form.Label>
                          {lang['MENU.TAGS']}{minCountTags > 0 && task.project.tags.length < 1 && (
                            <span style={{ color: 'red' }}>*</span>
                          )}
                        </Form.Label>
                        <SelectHashtag
                          className="kt-mb-15"
                          canCreate={false}
                          filter={(tag) => {
                            const inProject = (task.project.tags.findIndex((item) => item.id === tag.value) === -1);

                            if (currentRole === 'coloring-artist') {
                              return inProject && !SPECIAL_TAGS.includes(tag.slug);
                            }

                            return inProject;
                          }}
                          value={selectTags}
                          onChange={(tags) => {
                            this.setState({
                              selectTags: tags,
                            });
                          }}
                        />
                        {(task.project.tags.length < 1) ? (
                          <Form.Text>{lang['TAGS.PLEASE_CHOOSE'] + (minCountTags > 0 ? ` (${lang['GLOBAL.MIN']} ${minCountTags})` : '')}</Form.Text>
                        ) : <Form.Text>{lang['TAGS.PLEASE_CHOOSE']}</Form.Text>}
                      </Form.Group>
                    )}
                    {canEditTitle && (
                      <Form.Group>
                        <Form.Label>
                          {lang['GLOBAL.DESCRIPTION']}
                          <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                          as={'textarea'}
                          cols="30"
                          rows="2"
                          value={message === null
                            ? (task.project && task.project.description) || ''
                            : message
                          }
                          onChange={(event) => {
                            this.setState({
                              message: event.target.value,
                            });
                          }}
                        />
                        <Form.Text>{lang['GLOBAL.LEAVE_DESCRIPTION_PLEASE']}</Form.Text>
                      </Form.Group>
                    )}
                    <div className='d-flex justify-content-center kt-mt-a'>
                      <Button
                        variant='light'
                        className='btn-upper mx-1 font-weight-bold'
                        disabled={!canTurn || (title ? checkStringLength(title, 151) : false)}
                        type='submit'
                        onClick={this._onSubmitTurnWork}
                      >
                        {lang['GLOBAL.TURN']}
                      </Button>
                    </div>
                  </>)}
              </div>
            </div>
          </div>
        )}
        {isChoseGroup && (
          <div className={`col-xl-${sizingBlocks.xl} col-lg-${sizingBlocks.md} col-md-${sizingBlocks.md} col-sm-${sizingBlocks.sm} col-12`}>
            <form
              className="kt-portlet kt-bg-success kt-portlet--skin-solid kt-portlet--height-fluid"
              onSubmit={(event) => {
                event.preventDefault();
                this._chooseGroup(this.state.selectGroup);
              }}
            >
              <div className="kt-portlet__head kt-portlet__head--noborder">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title font-weight-bold">
                    {lang['GROUP.CHOOSE']}
                  </h3>
                </div>
              </div>
              <div className="kt-portlet__body kt-portlet__body--fullheight">
                <Form.Group>
                  <Form.Control
                    as={'select'}
                    value={this.state.selectGroup}
                    onChange={(event) => {
                      this.setState({
                        selectGroup: event.target.value,
                      });
                    }}
                  >
                    <option value={''}>{lang['GLOBAL.CHOOSE']}</option>
                    {groups.map((group) => {
                      return (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <div className='d-flex justify-content-center kt-mt-a'>
                  <Button
                    variant='light'
                    className='btn-upper mx-1 font-weight-bold'
                    type='submit'
                    disabled={!this.state.selectGroup}
                  >
                    {lang['GLOBAL.APPLY']}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
        {isChoseExecutor && (
          <div className={`col-xl-${sizingBlocks.xl} col-lg-${sizingBlocks.md} col-md-${sizingBlocks.md} col-sm-${sizingBlocks.sm} col-12`}>
            <UploadReference
              executors={executors}
              task={task}
              finishTask={this.props.finishTask}
              getTask={this.props.getTask}
            />
          </div>
        )}
      </>);
  }

  /**
   * Check File types
   *
   * @param {Array} filesTypes
   * @param {Array} filesLoading
   * @private
   * @returns {Array | boolean}
   */
  _checkFiles = (filesTypes, filesLoading) => {
    const { task } = this.props;

    // Sort files with prefix by length and filter each
    const fileWithPrefix = filesTypes.sort((prev, cur) => {
      return cur.prefix.length - prev.prefix.length;
    }).filter((file) => file.prefix || !file.prefix);

    const currentStep = fileWithPrefix[0].step;

    // Loaded files validation by project_id
    const validFiles = filesLoading.filter((item) => {
      if (currentStep === 'designer_coloring') {
        return item.file.name.indexOf(task.project_id) !== -1
          || item.file.name === 'texture.png' || [ 'texture.jpg', 'texture.jpeg' ].includes(item.file.name);
      }
      return true;
    });

    const loadedFilesCopy = [ ...validFiles ];
    const filesToUpload = [];

    fileWithPrefix.forEach((prefix) => {
      const prefixName = prefix.prefix.replace('_', '.');

      // eslint-disable-next-line fp/no-loops,guard-for-in,no-restricted-syntax
      for (const key in loadedFilesCopy) {
        const fileName = loadedFilesCopy[key].file.name;

        if (fileName.indexOf(prefixName) !== -1) {
          filesToUpload.push({
            file: loadedFilesCopy[key],
            prefix: prefixName === '' ? 'main' : prefixName.replace('.', '_'),
          });
          loadedFilesCopy.splice(key, 1);
          break;
        }
      }
      return true;
    });

    return [ ...filesToUpload ];
  };

  _onSubmitTurnWork = async () => {
    const { task, canSetTags } = this.props;

    const {
      canEditTitle,
      canTurn,
    } = this.computed;

    if (canTurn) {
      this.props.setTaskLoad(true);

      if (canEditTitle) {
        await this._editTitle();
      }
      if (canSetTags && this.state.selectTags.length > 0) {
        await addTagsToProject(this.state.selectTags, task.project_id);
      }
      this.props.finishTask();
    }
  };

  /**
   * Load Main files
   *
   * @param {Array} files
   * @param {Function} callback
   * @private
   * @returns {void}
   */
  _loadMainFiles = (files, callback = () => null) => {
    const { task } = this.props;

    this.props.setFilesLoad(true);

    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append('task_type', task.type);

    if (task.project) {
      fd.append('project', task.project.type);

      if (task.project.options && task.project.options.type) {
        fd.append('image_type', task.project.options.type);
      }
    }

    files.forEach((file) => {
      const nestedFile = file.file.file;

      fd.append(`files[${file.prefix}]`, nestedFile, nestedFile.name);
    });

    axiosApiInstance.post(`tasks/${task.id}/upload`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_TASK_LOAD_FILES', 'success');
        this.props.getTask();
        this.props.setFilesLoad(false, callback);
      })
      .catch((error) => {
        const errors = [];

        if (error.response) {
          const msg = error.response.data;

          if (msg.hasOwnProperty('errors')) {
            Object.keys(msg.errors).forEach((key) => {
              errors.push(msg.errors[key]);
            });
          } else if (typeof msg === 'object') {
            Object.keys(msg).forEach((key) => {
              if (key.includes('files.')) {
                errors.push(msg[key].map((item) => item.replace('files.', '').replace(' when task type is designer_coloring', '')).join(' '));
              } else {
                errors.push(msg[key]);
              }
            });
          }
        }

        this.props.notification('NOTIFICATION.ERROR_TASK_LOAD_FILES', 'error', errors);
        this.props.setFilesLoad(false);
      });
  };

  _editTitle = () => {
    const { task } = this.props;
    const { title, message } = this.state;

    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append('title', title === null ? (task.project && task.project.title) || '' : title);
    fd.append('description', message === null ? (task.project && task.project.description) || '' : message);

    axiosApiInstance.post(`projects/${this.props.task.project_id}/task`, fd, {})
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  /**
   * Choose group
   *
   * @param {int} selectGroup
   * @private
   * @returns {void}
   */
  _chooseGroup = (selectGroup) => {
    if (!selectGroup) {
      return;
    }

    this.props.setTaskLoad(true);
    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append('group_id', selectGroup);

    axiosApiInstance.post(`tasks/${this.props.task.id}/group`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_TASK_CHOOSE_GROUP', 'success');
        this.props.finishTask();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_TASK_CHOOSE_GROUP', 'error');
          this.props.setTaskLoad(false);
        }
      });
  };
}

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userId: store.user.user.id,
    currentRole: store.user.currentRole,
    config: store.config,
    userRoles: store.user.roles,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(TaskInfoFinishBlock);
