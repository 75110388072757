import taskTypes from '../taskTypes';



const getTaskDeadline = (task) => {
  const getTaskByTypeFromProject = (project, type) => {
    return (
      project.tasks.find((task) => task.type === type)
    );
  };

  if (task.type === taskTypes.editor_checking_artist.type
    || task.type === taskTypes.artist_choosing.type
    || task.type === taskTypes.artist_drawing.type
  ) {
    return getTaskByTypeFromProject(task.project, taskTypes.artist_drawing.type)?.deadline_to;
  }
  if (task.type === taskTypes.editor_checking_designer.type
    || task.type === taskTypes.designer_choosing.type
    || task.type === taskTypes.designer_coloring.type
  ) {
    return getTaskByTypeFromProject(task.project, taskTypes.designer_coloring.type)?.deadline_to;
  }
  if (task.type === taskTypes.device_testing.type) {
    return getTaskByTypeFromProject(task.project, taskTypes.device_testing.type)?.deadline_to;
  }

  return '';
};

export default getTaskDeadline;
